import React, { useMemo } from "react";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";

const CategoryDropdown = ({ label, options_data, values, setValues , name }) => {
  const dropdownOptions = useMemo(() => {
    let options = options_data?.map((each) => ({
      label: each?.title,
      value: each?.id,
    }));
    return options;
  }, [options_data]);
  return (
    <div className="mt-20">
      <CustomDropdown
        name={name}
        options={dropdownOptions}
        label={label}
        values={values}
        setValues={setValues}
        isClearable
        isMulti
      />
    </div>
  );
};

export default CategoryDropdown;
