import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";

const initialState = {
  data: null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllLuckydraw = createAsyncThunk(
  "fetchAllLuckydraw",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/luckydraw${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchPromotion = createAsyncThunk(
  "fetchluckydraw",
  (id, { rejectWithValue }) => {
    return Axios.get(`/luckydraw/${id}`).then((res) => res.data);
  }
);

export const LuckydrawSlice = createSlice({
  name: "Luckydraw",
  initialState,
  reducers: {
    clearDetailPromotionData: (state) => {
      state.detail = null;
    },
    setDetailPromotion: (state, action) => {
      state.detail = action.payload;
    },
    setLuckydraw: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllLuckydraw.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllLuckydraw.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllLuckydraw.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE promotion ////////////////
    builder.addCase(fetchPromotion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromotion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchPromotion.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default LuckydrawSlice.reducer;

/// SELECTORS
export const LuckydrawSelector = (store) => store.luckydraw;
export const LuckydrawLoader = (store) => store.luckydraw.isLoading;

export const { clearDetailPromotionData, setDetailPromotion ,setLuckydraw } =
  LuckydrawSlice.actions;
