export const tableColumns = [
  {
    header : "Title",
    accessor : "title"
  },
  {
    header : "Category",
    accessor : "category",
    type : 'dropdown'
  },
  {
    header : "Question",
    accessor : "question"
  },
  {
    header : "Answer 1",
    accessor : "answer1"
  },
  {
    header : "Answer 2",
    accessor : "answer2"
  },
  {
    header : "Youtube Link",
    accessor : "youtube_link",
    type : "video"
  },
  {
    header : "Actions",
    accessor : "",
    type : "actions"
  }
]

export const formFields =  [
  {
    header : "Title",
    accessor : "title"
  },
  {
    header : "Featured Image",
    accessor : "featured_image",
    type : "image"
  },
  {
    header : "Description",
    accessor : "text_area",
    type : 'richtext'
  },
  {
    header : "Category",
    accessor : "category",
    type : 'dropdown'
  },
  {
    header : "Assign to Brand or Shop",
    accessor : "shop_id",
    type : 'dropdown'
  },
  {
    header : "Youtube Link",
    accessor : "youtube_link",
    type : "video"
  },
  {
    header : "Question",
    accessor : "question"
  },
  {
    header : "Answer 1",
    accessor : "answer1"
  },
  {
    header : "Answer 2",
    accessor : "answer2"
  },

]

export const newsCategoryOptions = [
  {
    label : "Article",
    value : "article"
  },
  {
    label : "YouTube Link",
    value : "youtube_link"
  },
]
