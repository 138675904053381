import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import UseApiCalling from "../../hooks/UseApiCalling";
import Loader from "../../components/Loader";
import "./CreateForm.scss";
import SectionHeading from "../../components/Card/SectionHeading";
import File from "../../components/File";
import styles from "./ImagesAndCTA.module.sass";
import TextInput from "../../components/TextInput";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";

const generatePostivity = (rating_count) => {
  if (rating_count == 3) {
    return "Average";
  } else if (rating_count > 3) {
    return "Good";
  }
  return "Bad";
};
const title = "Feedback";
const CreateFeedbackForm = ({
  show,
  setShow,
  updateData,
  setUpdateData,
  related_entity_type,
  related_entity_id,
  fetchFeedbacksHandler,
}) => {
  const [values, setValues] = useState();
  const [errors, setErrors] = useState({});
  const { isLoading, callApi } = UseApiCalling();

  const closer = () => {
    setShow(false);
    setUpdateData(null);
  };

  const cb = (response, isSuccess) => {
    if (isSuccess) {
      setShow(false);
      setValues({});
      fetchFeedbacksHandler && fetchFeedbacksHandler();
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (!values?.rating) {
      toast.error("Give start rating pls");
    }
    console.log(values,'some values')
    let formdata = new FormData();
    formdata.append("positivity",generatePostivity(values?.rating) );
    formdata.append("comment_description", values?.comment_description || "");
    formdata.append("amount_purchased", values?.amount_purchased || 0);
    formdata.append("served_by", values?.served_by || "");
    formdata.append("rating_count", values?.rating || "");
    formdata.append("related_entity_type", related_entity_type || "");
    formdata.append("related_entity_id", related_entity_id || "");

    if (values?.image && typeof values?.image !== "string")
      formdata.append("image", values?.image);

    if (updateData) {
      formdata.append("_method", "PUT");
      callApi(`/feedbacks/${updateData.id}`, formdata, "post", cb);
    } else {
      callApi("/feedbacks", formdata, "post", cb);
    }
  };

  useEffect(() => {
    if (updateData) {
      let update = {
        name: updateData.name,
        image: updateData.image,
        facebook: updateData.facebook,
        instagram: updateData.instagram,
        twitter: updateData.twitter,
      };

      setValues(update);
    }
  }, [updateData, setUpdateData]);

  useEffect(() => {
    return () => {
      setUpdateData && setUpdateData(null);
    };
  }, []);

  const ratingChanged = (newRating) => {
    setValues({ ...values, rating: newRating });
  };

  return (
    <Modal outerClassName="modal_full" visible={show} onClose={closer}>
      {isLoading && <Loader className="page_loader" />}
      <SectionHeading
        title={updateData ? `Update ${title}` : `Add ${title}`}
        classTitle="title-blue"
      />
      <form onSubmit={formSubmitHandler}>
        <div className="rating">
          <p className="label">Star Rating</p>
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={24}
            activeColor="#ffd700"
            value={values?.rating || 0}
          />
          {values?.rating && (
            <p className="label">
              Positivity : {generatePostivity(values?.rating)}
            </p>
          )}
        </div>
        <div className={`${styles.images} mt-20`}>
          <File
            className={styles.field}
            title="Click or drop image"
            label="Shop Image"
            tooltip=""
            values={values}
            setValues={setValues}
            name="image"
          />
        </div>
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Description"
            name="comment_description"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Amount Purchased"
            name="amount_purchased"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Served By"
            name="served_by"
            type="text"
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <button type="submit" className={"button mt-30"}>
          {updateData ? `Update ${title}` : `Create ${title}`}
        </button>
      </form>
    </Modal>
  );
};

export default CreateFeedbackForm;
