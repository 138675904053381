import React, { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import styles from "./ModalAffliateSubmission.module.sass";
import Card from "../../components/Card";
import cn from "classnames";
import GAHistory from "./AffliateHistory";
import AffliateSubmitForm from "./AffliateSubmitForm";

const ModalAffliateSubmission = ({ visible, setIsVisible, project }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [editData, setEditData] = useState(null);

  const closer = () => {
    setIsVisible(false);
  };

  const navigation = ["Afllilate", "History"];
  return (
    <CustomModal visible={visible} onClose={closer}>
      <Card>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
        {activeIndex === 0 && (
          <AffliateSubmitForm
            setActiveIndex={setActiveIndex}
            project={project}
            editData={editData}
          />
        )}
        {activeIndex === 1 && (
          <GAHistory
            project={project}
            setActiveIndex={setActiveIndex}
            setEditData={setEditData}
          />
        )}
      </Card>
    </CustomModal>
  );
};

export default ModalAffliateSubmission;
