import { configureStore } from "@reduxjs/toolkit";
import staffReducer from "./staffSlice";
import userReducer from "./userSlice";
import categoryReducer from "./categoriesSlice";
import categoryTypesReducer from "./categoriesTypesSlice";
import shopsReducer from "./ShopSlice";
import PromotionReducer from "./promotionsSlice";
import NewsReducer from "./newsSlice";
import luckydrawReducer from "./luckydrawSlice";


export const store = configureStore({
  reducer: {
    staff: staffReducer,
    user: userReducer,
    categories : categoryReducer,
    categoryTypes : categoryTypesReducer,
    shops : shopsReducer,
    promotions : PromotionReducer,
    news : NewsReducer,
    luckydraw : luckydrawReducer
  },
});
