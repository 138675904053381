import moment from "moment";
export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const dateFormatter = (date) => {
  return moment(date).format("MMM D, YYYY [at] h:mm A");
};
export const dateFormatter2 = (date) => {
  return moment(date).format("MMM D, YYYY");
};
export const getMonthFromDate = (date) => {
  return moment(date).format("MMM YYYY");
};

export const getEndMonth = (x) => {
  const date = new Date(x);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let endOfMonth = new Date(year, month, 0);
  let formattedEndDate = endOfMonth.toISOString().slice(0, 10);
  return formattedEndDate;
};

export function validateForm(formValues, validations) {
  let errors = {};
  let isError = false;

  validations.forEach((validation) => {
    const { label, name, required } = validation;
    const value = formValues[name];
    if (required && (!value || !value?.length)) {
      errors[name] = [`${label} is the required Feild`];
      isError = true;
    }
  });
  return { isError, errors };
}

export const expiryDateCalculator = (date, months) => {
  const startDate = moment(date); // Replace with your actual start date
  const futureDate = startDate.add(months, "months");
  return futureDate.format("YYYY-MM-DD");
  // return futureDate;
};

export const formDataToObject = (formdata) => {
  var formDataObject = {};
  // Iterate through formdata entries
  for (var pair of formdata.entries()) {
    // pair[0] is the key, pair[1] is the value
    formDataObject[pair[0]] = pair[1];
  }
  // Now formDataObject contains all the form data
  return formDataObject;
};

export const agoCalculator = (date) => {
  const timeAgo = moment(date).fromNow();
  return timeAgo;
};

export const getRecentMonthOptions = (numMonths = 3) => {
  const today = new Date();
  const options = [];

  for (let i = numMonths - 1; i >= 0; i--) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 2);
    const formattedDate = date.toISOString().split("T")[0];
    options.push({
      value: formattedDate,
      label: `${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`,
    });
  }

  return options;
};

export const divide = (x, y, addDollar) => {
  if (!x || !y) {
    return "NA";
  }
  const result = x / y;
  const roundedResult = Math.round(result * 100) / 100;
  if (addDollar) return `$ ${roundedResult}`;
  return roundedResult;
};

export const getAvatar = (inputString) => {
  const words = inputString.split(" ");
  const firstLetters = words.map((word) => word[0]);
  return firstLetters.join("").substring(0, 2).toUpperCase();
};

export const groupDataByMonthAndYear = (data, dateKey) => {
  const groupedData = {};

  data?.forEach((entry) => {
    const createdAt = new Date(entry[dateKey]);
    const year = createdAt.getFullYear();
    const month = createdAt.getMonth() + 1;

    const yearMonthKey = `${year}-${month}`;

    if (!groupedData[yearMonthKey]) {
      groupedData[yearMonthKey] = [];
    }

    groupedData[yearMonthKey].push(entry);
  });

  return groupedData;
};

export const groupArrayByKey = (array, key) => {
  return array?.reduce((acc, current) => {
    const keyValue = current[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(current);
    return acc;
  }, {});
};

export function sortByKey(array, key, isdescending) {
  console.log(array,'some arary')
  if(!array) return
  const newArray = [...array];
  return newArray.sort((a, b) => {
    if (!isdescending) {
      return parseInt(a[key] || "0") - parseInt(b[key] || "0");
    } else {
      return parseInt(b[key] || "0") - parseInt(a[key] || "0");
    }
  });
}
