import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import { NewsLoader, NewsSelector, fetchAllNews } from "../../redux/newsSlice";
import { formFields, luckydrawCategoryOptions, tableColumns } from "./data";
import UseEditorState from "../../hooks/UseEditorState";
import UseApiCalling from "../../hooks/UseApiCalling";
import Loader from "../../components/Loader";
import Table from "../../MyComponents/CommonTable/Table";
import {
  LuckydrawLoader,
  LuckydrawSelector,
  fetchAllLuckydraw,
} from "../../redux/luckydrawSlice";

const LuckydrawPage = () => {
  const { generateHtml, generateState } = UseEditorState();
  const { isLoading, callApi } = UseApiCalling();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});

  const luckydraw = useSelector(LuckydrawSelector);
  const loading = useSelector(LuckydrawLoader);

  useEffect(() => {
    dispatch(fetchAllLuckydraw());
  }, []);

  const filterLuckydraw = useMemo(() => {
    return luckydraw.data;
  }, [values, luckydraw]);

  const generatePayload = (values) => {
    const formData = new FormData();
    let text_area = generateHtml(values?.text_area);
    
    formData.append("id", values.id);
    formData.append("title", values.title);
  
    if (typeof values.logo_image != "string")
      formData.append("logo_image", values.logo_image);
  
    if (typeof values.cover_image != "string")
      formData.append("cover_image", values.cover_image);
  
    formData.append("text_area", text_area);
    formData.append("category", values.category.value);
    formData.append("prize_amount", values.prize_amount);
    formData.append("entry_amount", values.entry_amount);
    formData.append("max_tickets_per_submission", values.max_tickets_per_submission);
    formData.append("lucky_number_digits", values.lucky_number_digits);
    formData.append("allow_upload_receipt", values.allow_upload_receipt);
    formData.append("expired_date", values.expired_date);
    formData.append("draw_date", values.draw_date);

  
    return formData;
  };
  


  const deleteClickHandler = (values) => {
    if (
      !window.confirm(
        `Are you sure you want to delete luckydraw "${values.title}" ? `
      )
    )
      return;
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        dispatch(fetchAllLuckydraw());
      }
    };
    callApi(`/luckydraw/${values.id}`, null, "delete", cb);
  };

  const createNewHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllLuckydraw());
      }
    };
    callApi("/luckydraw", payload, "post", cb);
  };
  const updateDataHandler = (values) => {
    let format = {};
    format.id = values.id;
    format.title = values.title;
    format.logo_image = values.logo_image;
    format.cover_image = values.cover_image;
    format.text_area = generateState(values.text_area);
    format.category = luckydrawCategoryOptions.find(
      (each) => each.value === values.category
    );
    format.prize_amount = values.prize_amount;
    format.entry_amount = values.entry_amount;
    format.max_tickets_per_submission = values.max_tickets_per_submission;
    format.lucky_number_digits = values.lucky_number_digits;
    format.allow_upload_receipt = values.allow_upload_receipt;
    format.expired_date = values.expired_date;
    format.draw_date = values.draw_date;  
    return format;
  };
  const updateApiHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    payload.append("_method", "put");
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllNews());
      }
    };
    callApi(`/luckydraw/${values.id}`, payload, "post", cb);
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <Loader />}

        <Table
          actions
          deleteClickHanddler={deleteClickHandler}
          loading={loading}
          tableData={filterLuckydraw || []}
          formFields={formFields}
          tableColumns={tableColumns}
          options={{
            category: luckydrawCategoryOptions,
          }}
          createNewHandler={createNewHandler}
          updateDataHandler={updateDataHandler}
          updateApiHandler={updateApiHandler}
          title={"Luckydraw"}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default LuckydrawPage;
