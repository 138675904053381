import Icon from "../../components/Icon";

export const columns = [
  {
    header: "Month",
    accessor: "report_month",
  },
  {
    header: "Bitly count",
    accessor: "bitly_count",
  },
  {
    header: "CN | REG | FDU | MDP",
    accessor: "country_affliates",
  },
  // {
  //   header: "Bitly Registrations",
  //   accessor: "bitly_registrations",
  //   className: "number",
  // },
  // {
  //   header: "Bitly FDP",
  //   accessor: "bitly_fdp",
  //   className: "number",
  // },
  // {
  //   header: "E. User",
  //   accessor: "eu_user",
  //   className: "number",
  // },
  // {
  //   header: "EU Bitly",
  //   accessor: "eu_bitly",
  //   className: "number",
  // },
  // {
  //   header: "EU Registartion",
  //   accessor: "eu_registration",
  //   className: "number",
  // },
  // {
  //   header: "EU FDP",
  //   accessor: "eu_fdp",
  //   className: "number",
  // },

];

export const actions = [
  {
    html: <Icon name="edit" size="24" />,
    type: "edit",
  },
  {
    icon: "view",
    html: <Icon name="expand" size="24" />,
  },
];

const samplePayload = {
  project_id: 20,
  report_month: "2024-02-05",
  bitly_count: 100,
  bitly_registrations: 50,
  bitly_fdp: 20,
  eu_user: 30,
  eu_bitly: 15,
  eu_registration: 10,
  eu_fdp: 5,
  country_affliates: [
    {
      country: "India",
      country_registrations: 25,
      country_fdu: 10,
      country_percent: 15.5,
      country_deposits: 50,
      country_a_registrations: 12,
      country_a_deposits: 30,
    },
  ],
};
