import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";

const initialState = {
  data: null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllPromotions = createAsyncThunk(
  "fetchAllPromotions",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/promotions${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchPromotion = createAsyncThunk(
  "fetchPromotion",
  (id, { rejectWithValue }) => {
    return Axios.get(`/promotions/${id}`).then((res) => res.data);
  }
);

export const PromotionsSlice = createSlice({
  name: "Promotions",
  initialState,
  reducers: {
    clearDetailPromotionData: (state) => {
      state.detail = null;
    },
    setDetailPromotion: (state, action) => {
      state.detail = action.payload;
    },
    setPromotions: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllPromotions.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllPromotions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllPromotions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE promotion ////////////////
    builder.addCase(fetchPromotion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromotion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchPromotion.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default PromotionsSlice.reducer;

/// SELECTORS
export const PromotionsSelector = (store) => store.promotions;
export const PromotionsLoader = (store) => store.promotions.isLoading;

export const { clearDetailPromotionData, setDetailPromotion ,setPromotions } =
  PromotionsSlice.actions;
