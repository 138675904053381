import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";

const Panel = ({ className }) => {
  return (
    <>
      <div className={cn(styles.panel, className)}>
        <div className={styles.avatar}>
          <img src="/images/content/avatar.jpg" alt="Avatar" />
        </div>
      </div>
    </>
  );
};

export default Panel;
