import React, { useEffect } from "react";
import styles from "./Payouts.module.sass";
import ServerTableCard from "./CategoriesTableCard";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import {
  categoriesTypesLoader,
  categoriesTypesSelector,
  fetchAllCategoriesTypes,
} from "../../redux/categoriesTypesSlice";

const CategoryTypesListing = () => {
  const dispatch = useDispatch();

  const categories = useSelector(categoriesTypesSelector);
  const loading = useSelector(categoriesTypesLoader);

  useEffect(() => {
    if(!categories?.data)
    dispatch(fetchAllCategoriesTypes());
  }, [dispatch]);

  console.log(categories, "some categories");

  return (
    <>
      <div className={styles.section}>
     
        <ServerTableCard loading={loading} hostingServers={categories.data} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CategoryTypesListing;
