import React, { useEffect } from "react";
import Table from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSalaryClaims,
  selectSalaryClaims,
} from "../../redux/salaryClaimSlice";
import Loader from "../../components/Loader";

const ClaimStatus = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllSalaryClaims());
  }, []);
  const claimData = useSelector(selectSalaryClaims);
  console.log(claimData,'claim data')
  return (
    <div>
      {claimData.isLoading && <Loader />}
      <Table data={claimData?.data} />
    </div>
  );
};

export default ClaimStatus;
