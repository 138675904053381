import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.sass";
import ServerTableCard from "./CategoriesTableCard";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import {
  categoriesLoader,
  categoriesSelector,
  fetchAllCategories,
} from "../../redux/categoriesSlice";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import Card from "../../components/Card";
import {
  categoriesTypesSelector,
  fetchAllCategoriesTypes,
} from "../../redux/categoriesTypesSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CategoriesListing = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const { type } = useParams();

  const categories = useSelector(categoriesSelector);
  const categorytypes = useSelector(categoriesTypesSelector);
  const loading = useSelector(categoriesLoader);

  useEffect(() => {
    dispatch(fetchAllCategories());
    dispatch(fetchAllCategoriesTypes());
  }, [dispatch]);

  const categoryOptions = useMemo(() => {
    let categoryOptions = categorytypes.data?.map((each) => ({
      label: each.title,
      value: each.id,
    }));
    return categoryOptions;
  }, [categorytypes]);

  const filterCategories = useMemo(() => {
    if (!type) return categories?.data;
    let data = categories?.data?.filter(
      (each) => each?.type_id == type
    );
    return data;
  }, [type, categories]);

  return (
    <>
      <div className={styles.section}>
        {/* <Card className={styles.card} title="Filters" classTitle="title-blue">
          <div className="flex gap-20">
            <div style={{ minWidth: "250px" }}>
              <CustomDropdown
                isClearable
                values={values}
                setValues={setValues}
                label={`Category Type Filter`}
                name={`category_type`}
                options={categoryOptions}
              />
            </div>
          </div>
        </Card> */}
        <ServerTableCard loading={loading} hostingServers={filterCategories} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default CategoriesListing;
