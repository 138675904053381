import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { clearUserInfo, selectUserInfo } from "../../../redux/userSlice";
import ClaimSalaryForm from "../../../Pages/ClaimStatus/ClaimSalaryForm/ClaimSalaryForm";
import { items } from "./data";
import CustomImage from "../../CustomImage/CustomImage";

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [showClaimForm, setShowClaimForm] = useState(false);

  const dispatch = useDispatch();
  const navItemClickHandler = (x) => {
    setVisible(false);
    if (x.title == "Log out") {
      dispatch(clearUserInfo());
    } else if (x.title == "Claim My Salary") {
      setShowClaimForm(true);
    }
  };

  const user = useSelector(selectUserInfo);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {showClaimForm && (
        <ClaimSalaryForm
          visible={showClaimForm}
          closer={() => {
            setShowClaimForm(false);
          }}
        />
      )}
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          {user?.profile_image ? (
            <CustomImage
              src={user?.profile_image || "/images/content/avatar.jpg"}
              alt="Avatar"
            />
          ) : (
            <img src="/images/content/avatar.jpg" alt="Avatar" />
          )}
        </button>
        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, { [styles.color]: x.color })}
                    activeClassName={styles.active}
                    to={x.url}
                    onClick={() => navItemClickHandler(x)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={() => navItemClickHandler(x)}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
