import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { signWithGoogle, signupUser } from "../../redux/userSlice";
import { toast } from "react-toastify";

const SignUpPage = () => {
  const heightWindow = use100vh();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const { isLoading } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (values?.password !== values?.c_password) {
      toast.error("Password and Confirm Password is not the same");
      return;
    }
    if( values?.password?.length < 6){
      toast.error("Password atleast 6 characters");
      return;
    }

    dispatch(signupUser(values));
  };

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      let payload = {
        code: codeResponse.code,
      };
      dispatch(signWithGoogle(payload));
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      {isLoading && <Loader className="page_loader" />}
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign Up</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>Sign up with Open account</div>
          <div className={styles.btns}>
            <button
              onClick={googleLogin}
              className={cn("button-stroke", styles.button)}
            >
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.subtitle}>Or continue with email address</div>
          <form onSubmit={formSubmitHandler}>
            <TextInput
              className={styles.field}
              name="name"
              type="text"
              placeholder="Your Name"
              required
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <TextInput
              className={styles.field}
              name="c_password"
              type="password"
              placeholder="Confirm Password"
              required
              icon="lock"
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <button className={cn("button", styles.button)}>Sign up</button>
          </form>


          <div className={styles.info}>
            Already have an account?{" "}
            <Link className={styles.link} to="/sign-in">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
