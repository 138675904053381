import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  categoriesTypesWithCategories : null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllCategoriesTypes = createAsyncThunk(
  "fetchAllCategoriesTypes",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/category-types${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchAllCategoriesTypesWithCategories = createAsyncThunk(
  "fetchAllCategoriesTypes",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/category-types?populate=categories`).then((res) => res.data);
  }
);
export const fetchCategoryType = createAsyncThunk(
  "fetchCategoryType",
  (id, { rejectWithValue }) => {
    return Axios.get(`/category-types/${id}`).then((res) => res.data);
  }
);


export const categoriesTypesSlice = createSlice({
  name: "categoriesTypes",
  initialState,
  reducers: {
    clearDetailCategeryType: (state) => {
      state.detail = null;
    },
    clearAllCategoryTypeError: (state, action) => {
      state.error = null;
    },
    setCategoryDetailType: (state, action) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategoriesTypes.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllCategoriesTypes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllCategoriesTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE category ////////////////
    builder.addCase(fetchCategoryType.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategoryType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchCategoryType.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

  },
});

export default categoriesTypesSlice.reducer;

/// SELECTORS
export const categoriesTypesSelector = (store) => store.categoryTypes;
export const categoriesTypesLoader = (store) => store.categoryTypes.isLoading



export const {
    clearDetailCategeryType,
    clearAllCategoryTypeError,
    setCategoryDetailType,
} = categoriesTypesSlice.actions;

