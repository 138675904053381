import React from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";
import Select from "react-select";
import "./CustomDropdown.scss";

const CustomDropdown = ({
  options,
  values,
  setValues,
  isMulti,
  name,
  label,
  classDropdownLabel,
  tooltip,
  placeholder,
  isClearable,
  errors,
  setErrors,
  required
  
}) => {
  const selectChangeHandler = (value) => {
    console.log(value);
    setValues({ ...values, [name]: value });
    if (errors && errors?.[name]) setErrors({ ...errors, [name]: null });
  };
  return (
    <div>
      {label && (
        <div className={cn(styles.label, classDropdownLabel)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <Select
         required={required}
        isClearable={isClearable}
        placeholder={placeholder}
        isMulti={isMulti}
        options={options}
        className={errors && errors?.[name]?.length && "error_input"}
        classNamePrefix="custom_select"
        value={values?.[name] || null}
        onChange={selectChangeHandler}
      />

      {errors && errors?.[name]?.length && (
        <div className="input_errors_cont">
          {errors?.[name]?.map((error) => (
            <p className="error_msg">{error}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
