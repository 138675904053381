export const  items = [
    // {
    //   menu: [
    //     {
    //       title: "Profile",
    //       url: "/profile",
    //     },
    //     {
    //       title: "Edit profile",
    //       url: "/settings",
    //     },
    //   ],
    // },
    {
      menu: [
        {
          title: "Log out",
        },
      ],
    },
  ];
  