export const paymentOptionTypes = {
    upwork : "Upwork",
    freelance : "Freelance",
    fiver : "Fiver",
    paypal : "Paypal",
    localBank : "Local Bank",
    Binance : "Binance"
}
export const paymentTypeOptions = [
    {
        label: paymentOptionTypes.upwork,
        value: paymentOptionTypes.upwork
    },
    {
        label: paymentOptionTypes.freelance,
        value: paymentOptionTypes.freelance
    },
    {
        label: paymentOptionTypes.fiver,
        value: paymentOptionTypes.fiver
    },
    {
        label: paymentOptionTypes.paypal,
        value: paymentOptionTypes.paypal
    },
    {
        label: paymentOptionTypes.localBank,
        value: paymentOptionTypes.localBank
    },
    {
        label: paymentOptionTypes.Binance,
        value: paymentOptionTypes.Binance
    }
];
