import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.sass";

import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopOptions, shopOptionsSelector } from "../../redux/ShopSlice";
import Card from "../../components/Card";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { NewsLoader, NewsSelector, fetchAllNews } from "../../redux/newsSlice";
import { formFields, newsCategoryOptions, tableColumns } from "./data";
import UseEditorState from "../../hooks/UseEditorState";
import UseApiCalling from "../../hooks/UseApiCalling";
import Loader from "../../components/Loader";
import Table from "../../MyComponents/CommonTable/Table";

const NewsPage = () => {
  const { generateHtml, generateState } = UseEditorState();
  const { isLoading, callApi } = UseApiCalling();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});

  const news = useSelector(NewsSelector);
  const loading = useSelector(NewsLoader);

  useEffect(() => {
    dispatch(fetchAllNews());
    dispatch(fetchShopOptions());
  }, []);

  const filterNews = useMemo(() => {
    if (!values.type_shop) return news.data;
    else {
      let filteredNews = news?.data?.filter(
        (each) => each.related_entity_id === values.type_shop?.value
      );
      return filteredNews;
    }
  }, [values, news]);

  const generatePayload = (values) => {
    const formData = new FormData();
    let text_area = generateHtml(values?.text_area);
    formData.append("title", values.title);
    if (typeof values.featured_image != "string")
      formData.append("featured_image", values.featured_image);
    formData.append("text_area", text_area);
    formData.append("category", values.category.value);
    formData.append("question", values.question);
    formData.append("answer1", values.answer1);
    formData.append("answer2", values.answer2);
    formData.append("correct_answer", values.correct_answer);
    formData.append("youtube_link", values.youtube_link);
    if (values.upload_video)
      formData.append("upload_video", values.upload_video);
    if (values.shop_id) formData.append("shop_id", values.shop_id?.value);
    return formData;
  };

  const shopOptions = useSelector(shopOptionsSelector);

  const deleteClickHandler = (values) => {
    if (
      !window.confirm(
        `Are you sure you want to delete news "${values.title}" ? `
      )
    )
      return;
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        dispatch(fetchAllNews());
      }
    };
    callApi(`/news/${values.id}`, null, "delete", cb);
  };

  const createNewHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllNews());
      }
    };
    callApi("/news", payload, "post", cb);
  };
  const updateDataHandler = (values) => {
    let format = {};
    format.id = values.id;
    format.title = values.title;
    format.text_area = generateState(values.text_area);
    format.question = values.question;
    format.answer1 = values.answer1;
    format.answer2 = values.answer2;
    format.correct_answer = values.correct_answer;
    format.youtube_link = values.youtube_link || "";
    format.featured_image = values.featured_image;


    // if (values.upload_video && values.upload_video instanceof File) {
    //   format.upload_video = values.upload_video;
    // }

    format.category = newsCategoryOptions.find(
      (each) => each.value === values.category
    );
    format.shop_id = shopOptions.find((each) => each.value === values.shop_id);

    return format;
  };
  const updateApiHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    payload.append("_method", "put");
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllNews());
      }
    };
    callApi(`/news/${values.id}`, payload, "post", cb);
  };

  return (
    <>
      <div className={styles.section}>
        {loading && <Loader />}
        <Card className={styles.card} title="Filters" classTitle="title-blue">
          <div style={{ flexWrap: "wrap" }} className="flex gap-20 flex-wrap">
            <CustomDropdown
              values={values}
              setValues={setValues}
              label={`Filter by Shop`}
              options={shopOptions || []}
              name={`type_shop`}
              isClearable
            />
          </div>
        </Card>

        <Table
          actions
          deleteClickHanddler={deleteClickHandler}
          loading={loading}
          tableData={filterNews}
          formFields={formFields}
          tableColumns = {tableColumns}
          options={{
            category: newsCategoryOptions,
            shop_id: shopOptions,
          }}
          createNewHandler={createNewHandler}
          updateDataHandler={updateDataHandler}
          updateApiHandler={updateApiHandler}
          title={"News"}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default NewsPage;
