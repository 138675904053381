import React, { useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./RefundRequests.module.sass";
import Card from "../Card";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import { staffOverviewSelector } from "../../redux/staffSlice";



const RefundRequests = ({ className, title, classTitle }) => {

  const staffoverview = useSelector(staffOverviewSelector)
  const requests = useMemo(()=>{
    const data = [
      {
        content:
          `You have <strong>${staffoverview.not_reviewed_salary_count||0} open Claim requests</strong> to action. <span role="img" aria-label="smile">👀</span>`,
        icon: "basket",
        fill: "#FF6A55",
        color: "#FFE7E4",
      },
    ];
    return data
  },[staffoverview])

  return (
    <Card
      className={cn(styles.card, className)}
      title={title}
      classTitle={classTitle}
    >
      <div className={styles.section}>
        <div className={styles.list}>
          {requests.map((x, index) => (
            <div className={styles.item} key={index}>
              <div className={styles.icon} style={{ backgroundColor: x.color }}>
                <Icon name={x.icon} size="24" fill={x.fill} />
              </div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: x.content }}
              ></div>
            </div>
          ))}
        </div>
        <Link
          className={cn("button-stroke", styles.button)}
          to="/customers/claim-status"
        >
          Review Claim requests
        </Link>
      </div>
    </Card>
  );
};

export default RefundRequests;
