import React from "react";
import { Route } from "react-router-dom";
import Page from "./components/Page";
import Customers from "./Pages/Customers";
import CustomerList from "./Pages/CustomerList";
import PageList from "./screens/PageList";
import "./App.scss";
import Home from "./Pages/Home";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import UserRefreshLogin from "./hooks/UserRefreshLogin";
import HostingServerPage from "./Pages/CategoriesListing";
import SignIn from "./Pages/SignIn";
import SignUpPage from "./Pages/SignUpPage/SignUpPage";
import NewStaff from "./Pages/NewStaff/NewStaff";
import ClaimStatus from "./Pages/ClaimStatus/ClaimStatus";
import CategoryTypesListing from "./Pages/CategoryTypesListing";
import ShopsListing from "./Pages/Shops";
import ShopDetailPage from "./Pages/Shops/ShopDetailPage/ShopDetailPage";
import ShopSections from "./Pages/Shops/ShopSections/ShopSections";
import ServiceDetailPage from "./Pages/Shops/ServiceDetailPage/ServiceDetailPage";
import PromotionsListing from "./Pages/PromotionsPage";
import NewsPage from "./Pages/NewsPage";
import LuckydrawPage from "./Pages/LuckydrawPage";

const Routes = () => {
  UserRefreshLogin();
  return (
    <>
      <Route
        exact
        path="/"
        render={() => (
          <Page title="Dashboard">
            <Home />
          </Page>
        )}
      />

      <Route
        exact
        path="/customers/overview"
        render={() => (
          <Page title="Customers">
            <Customers />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/customer-list"
        render={() => (
          <Page title="Staff list">
            <CustomerList />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/claim-status"
        render={() => (
          <Page title="Claim Status">
            <ClaimStatus />
          </Page>
        )}
      />
      <Route
        exact
        path="/customers/add"
        render={() => (
          <Page title="Add New Staff">
            <NewStaff />
          </Page>
        )}
      />
      <Route
        exact
        path="/profile"
        render={() => (
          <Page wide>
            <ProfilePage />
          </Page>
        )}
      />

      <Route
        exact
        path="/categories/listing/:type"
        render={() => (
          <Page title="Categories Listing">
            <HostingServerPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/categories/types"
        render={() => (
          <Page title="Categories types">
            <CategoryTypesListing />
          </Page>
        )}
      />

      <Route
        exact
        path="/shop/listing"
        render={() => (
          <Page title="Shops Listing">
            <ShopsListing />
          </Page>
        )}
      />
      <Route
        exact
        path="/shop/sections"
        render={() => (
          <Page title="Shops Sections">
            <ShopSections />
          </Page>
        )}
      />
      <Route
        exact
        path="/shop/listing/:id"
        render={() => (
          <Page title="Shop Detail">
            <ShopDetailPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/promotion/listing"
        render={() => (
          <Page title="Promotions Listing">
            <PromotionsListing />
          </Page>
        )}
      />
      <Route
        exact
        path="/services/:id"
        render={() => (
          <Page title="Service Detail">
            <ServiceDetailPage />
          </Page>
        )}
      />

      {/* NEWS ROUTES */}
      <Route
        exact
        path="/news/listing"
        render={() => (
          <Page title="News Listing">
            <NewsPage />
          </Page>
        )}
      />
      <Route
        exact
        path="/luckydraw/listing"
        render={() => (
          <Page title="Luckydraw">
            <LuckydrawPage />
          </Page>
        )}
      />


      <Route exact path="/sign-up" render={() => <SignUpPage />} />

      <Route exact path="/sign-in" render={() => <SignIn />} />
      <Route exact path="/pagelist" component={PageList} />
    </>
  );
};

export default Routes;
