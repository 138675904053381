import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";

const initialState = {
  data: null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllNews = createAsyncThunk(
  "fetchAllNews",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/news${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchPromotion = createAsyncThunk(
  "fetchPromotion",
  (id, { rejectWithValue }) => {
    return Axios.get(`/news/${id}`).then((res) => res.data);
  }
);

export const NewsSlice = createSlice({
  name: "News",
  initialState,
  reducers: {
    clearDetailPromotionData: (state) => {
      state.detail = null;
    },
    setDetailPromotion: (state, action) => {
      state.detail = action.payload;
    },
    setNews: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllNews.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllNews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllNews.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE promotion ////////////////
    builder.addCase(fetchPromotion.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPromotion.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchPromotion.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default NewsSlice.reducer;

/// SELECTORS
export const NewsSelector = (store) => store.news;
export const NewsLoader = (store) => store.news.isLoading;

export const { clearDetailPromotionData, setDetailPromotion ,setNews } =
  NewsSlice.actions;
