import { ContentState, EditorState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const UseEditorState = () => {
  const generateHtml = (data) => {
    const contentState = data?.getCurrentContent();
    let contentHtml;
    if (contentState) contentHtml = stateToHTML(contentState);
    return contentHtml;
  };
  const generateState = (data) => {
    const contentBlocks = convertFromHTML(data);
    const contentState = ContentState.createFromBlockArray(contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  return { generateHtml, generateState };
};
export default UseEditorState;
