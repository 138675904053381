import React from "react";
import { SHOP_SECTIONS } from "../ShopSections/data";
import ShopMediaSection from "./ShopMediaSection";

const ShopMedia = () => {
  return (
    <>
      {SHOP_SECTIONS?.map((each) => (
        <ShopMediaSection each={each} />
      ))}
    </>
  );
};

export default ShopMedia;
