import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { toast } from "react-toastify";
import { groupArrayByKey } from "../utils";

const initialState = {
  data: null,
  isLoading: false,
  shop_media: null,
  detail: null,
  service_detail: null,
  shop_options: null,
  error: null,
};

export const fetchAllShops = createAsyncThunk(
  "fetchAllShops",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/shops${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchShopMedia = createAsyncThunk(
  "fetchShopMedia",
  (shop_id, { rejectWithValue }) => {
    return Axios.get(`/shop-media?shop_id=${shop_id || ""}`).then(
      (res) => res.data
    );
  }
);
export const fetchShop = createAsyncThunk(
  "fetchShop",
  (id, { rejectWithValue }) => {
    return Axios.get(`/shops/${id}`).then((res) => res.data);
  }
);
export const fetchShopOptions = createAsyncThunk(
  "fetchShopOptions",
  (id, { rejectWithValue }) => {
    return Axios.get(`/shops-options`).then((res) => res.data);
  }
);

export const fetchShopService = createAsyncThunk(
  "fetchService",
  (id, { rejectWithValue }) => {
    return Axios.get(`/shop-service/${id}`).then((res) => res.data);
  }
);

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    clearDetailCategeryType: (state) => {
      state.detail = null;
    },
    clearAllCategoryTypeError: (state, action) => {
      state.error = null;
    },
    setCategoryDetailType: (state, action) => {
      state.detail = action.payload;
    },
    setShops: (state, action) => {
      state.data = action.payload;
    },
    clearShopDetail: (state) => {
      state.detail = null;
      state.shop_media = null;
    },
    clearShopServiceDetail: (state) => {
      state.detail = null;
      state.service_detail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllShops.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllShops.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllShops.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE Shop ////////////////
    builder.addCase(fetchShop.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchShop.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
      state.shop_media = action.payload?.data?.media;
    });
    builder.addCase(fetchShop.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////// FETCH SINGLE Shop ////////////////
    builder.addCase(fetchShopMedia.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchShopMedia.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shop_media = action.payload?.data;
    });
    builder.addCase(fetchShopMedia.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////// FETCH SHOP SERVICE ////////////////
    builder.addCase(fetchShopService.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchShopService.fulfilled, (state, action) => {
      state.isLoading = false;
      state.service_detail = action.payload?.data;
    });
    builder.addCase(fetchShopService.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SHOP OPTIONS ////////////////
    builder.addCase(fetchShopOptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchShopOptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.shop_options = action.payload?.data;
    });
    builder.addCase(fetchShopOptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default shopsSlice.reducer;

/// SELECTORS
export const shopsSelector = (store) => store.shops;
export const shopsLoader = (store) => store.shops.isLoading;
export const shopMediaSelector = (store) => {
  let media = store.shops.shop_media;
  let grouped_data = groupArrayByKey(media, "section");
  return grouped_data;
};

export const shopOptionsSelector = (store) => {
  let shopOptions = store.shops.shop_options;
  let options = shopOptions?.map((each) => ({
    label: each.name,
    value: each.id,
  }));
  return options;
};

export const {
  clearDetailCategeryType,
  clearAllCategoryTypeError,
  setCategoryDetailType,
  setShops,
  clearShopDetail,
  clearShopServiceDetail,
} = shopsSlice.actions;
