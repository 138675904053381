import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import "./CreateForm.scss";
import SectionHeading from "../../../components/Card/SectionHeading";
import Editor from "../../../components/Editor";
import File from "../../../components/File";
import styles from "./ImagesAndCTA.module.sass";
import CustomSwitch from "../../../MyComponents/Switch";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import Loader from "../../../components/Loader";

const CreateForm = ({
  show,
  setShow,
  updateData,
  setUpdateData,
  formColumns,
  createNewHandler,
  updateDataHandler,
  updateApiHandler,
  isLoading,
  options,
  title=""
}) => {
  const [values, setValues] = useState({
    description: null,
  });
  const [loading,setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const closer = () => {
    setShow(false);
    setUpdateData(null);
  };

  const cb = () => {
    setShow(false);
    setValues({});
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true)
    if (updateData) {
      updateApiHandler(values, cb);
    } else {
      createNewHandler(values, cb);
    }
  };

  useEffect(() => {
    if (updateData) {
      let updatedFormValues = updateDataHandler(updateData);
      setValues(updatedFormValues);
    }
  }, [updateData,options]);

  return (
    <Modal outerClassName="modal_full" visible={show} onClose={closer}>
      <SectionHeading
        title={updateData ? `Update ${title}` : `Add ${title}`}
        classTitle="title-blue"
      />
      {loading&&<Loader />}
      <form onSubmit={formSubmitHandler}>
        {formColumns?.map((each) => {
          if (each.type === "image") {
            return (
              <div className={`${styles.images} mt-20`}>
                <File
                  className={styles.field}
                  title="Click or drop image"
                  label={each.header}
                  tooltip=""
                  values={values}
                  setValues={setValues}
                  name={each.accessor}
                />
              </div>
            );
          } else if (each.type === "dropdown") {
            return (
              <div className="mt-20">
                <CustomDropdown
                  values={values}
                  setValues={setValues}
                  label={each.header}
                  options={options?.[each?.accessor] || []}
                  name={each.accessor}
                />
              </div>
            );
          } else if (each.type === "switch") {
            return (
              <div className="mt-20">
                <CustomSwitch
                  values={values}
                  setValues={setValues}
                  label={each.header}
                  name={each.accessor}
                  type="text"
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            );
          } else if (each.type === "richtext") {
            return (
              <div className="mt-20">
                <Editor
                  label={each.header}
                  name={each.accessor}
                  values={values}
                  setValues={setValues}
                />
              </div>
            );
          } else {
            return (
              <div className={`${styles.images} mt-20`}>
                <TextInput
                  values={values}
                  setValues={setValues}
                  label={each.header}
                  name={each.accessor}
                  type={each.type || "text"}
                  required
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            );
          }
        })}

        <button disabled={loading} type="submit" className={"button mt-30"}>
          {updateData ? `Update ${title}` : `Create ${title}`}
        </button>
      </form>
    </Modal>
  );
};

export default CreateForm;
