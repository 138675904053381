import React from "react";
import CustomModal from "../../../MyComponents/CustomModal/CustomModal";
import Card from "../../../components/Card";
import "./ClaimSalaryForm.scss";
import { getMonthFromDate } from "../../../utils";
import { claimTypeValueToLabel } from "../ClaimSalaryForm/data";
import UserComponent from "../../CustomerList/Table/Row/UserComponent/UserComponent";
import ReactHtmlParser from "react-html-parser";
import Pill from "../../../MyComponents/Pill/Pill";
import UseApiCalling from "../../../hooks/UseApiCalling";
import Loader from "../../../components/Loader";
import { useDispatch } from "react-redux";
import { fetchAllSalaryClaims } from "../../../redux/salaryClaimSlice";
import { paymentOptionTypes } from "../../../screens/Settings/Payment/data";

const ClaimDetails = ({ closer, data }) => {
  const { isLoading, callApi } = UseApiCalling();

  const total_claim_amount =
    parseInt(data?.latest_salary_submission?.claim_amount || 0) +
    parseInt(data?.latest_salary_submission?.claim_locked_fund || 0) -
    parseInt(data?.latest_salary_submission?.deduct_debit || 0);

  const dispatch = useDispatch();

  const cb = (response, isSuccess) => {
    if (isSuccess) {
      dispatch(fetchAllSalaryClaims());
      closer();
    }
  };

  const approveOrRejectHandler = (isApprove) => {
    let payload = {
      status: isApprove ? "approved" : "rejected",
    };
    callApi(`/salary-submission-approve/${data?.latest_salary_submission?.id}`, payload, "post", cb);
  };


  return (
    <CustomModal visible={true} onClose={closer}>
      {isLoading && <Loader />}
      <Card title="Salary Request" classTitle="title-green">
        <div className="claim_details">
          <div className="left_cont">
            <div className="profile_info row">
              <UserComponent
                avatar={data?.latest_salary_submission?.user?.image || ""}
                description={
                  data?.latest_salary_submission?.user?.position || ""
                }
                name={data?.latest_salary_submission?.user?.name || ""}
              />
            </div>
            <div className="row">
              <p className="label">Which Month</p>
              <p className="value">
                {getMonthFromDate(
                  data?.latest_salary_submission?.claim_from || ""
                )}
              </p>
            </div>
            <div className="row">
              <p className="label">Salary Amount</p>

              <p className="value">
                <Pill isGreenDark>
                  $ {data?.latest_salary_submission?.claim_amount}
                </Pill>
              </p>
            </div>
            <div className="row">
              <p className="label">Locked Fund Claim</p>
              <p className="value">
                <Pill isGreenDark>
                  $ {data?.latest_salary_submission?.claim_locked_fund}
                </Pill>
              </p>
            </div>
            <div className="row">
              <p className="label">Debt Deduct</p>
              <p className="value">
                <Pill isRed>
                  $ {data?.latest_salary_submission?.claim_locked_fund}
                </Pill>
              </p>
            </div>
            <div className="row">
              <p className="label">Request Id</p>
              <p className="value">{data.id}</p>
            </div>
            <div className="row">
              <p className="label">Claim Type</p>
              <p className="value">
                {claimTypeValueToLabel[
                  data?.latest_salary_submission?.claim_type
                ] || ""}
              </p>
            </div>
            <div className="row">
              <p className="label">Payment Type</p>
              {paymentOptionTypes.Binance === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.payment}</p>
                  <p className="value">{data?.usdt_address}</p>
                  <p className="value">{data?.binance_email}</p>
                </div>
              )}
              {paymentOptionTypes.fiver === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.fiver_id}</p>
                </div>
              )}
              {paymentOptionTypes.freelance === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.freelancer_id}</p>
                </div>
              )}
              {paymentOptionTypes.localBank === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.local_bank_details}</p>
                </div>
              )}
              {paymentOptionTypes.paypal === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.paypal_email}</p>
                </div>
              )}
              {paymentOptionTypes.upwork === data?.payment && (
                <div className="text-right">
                  <p className="value">{data?.upwork_id}</p>
                </div>
              )}
              {!data?.payment && (
                <div className="text-right">
                  <p className="value">No payment Details Updated</p>
                </div>
              )}
            </div>
            <div className="row">
              <p className="label">Final Amount</p>
              <p className="value">$ {total_claim_amount}</p>
            </div>
            <div className="gap-20 flex buttons_group">
              {data?.latest_salary_submission?.status == "claim" && (
                <>
                  <button
                    onClick={() => approveOrRejectHandler(false)}
                    className="button-stroke-red"
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => approveOrRejectHandler(true)}
                    className="button"
                  >
                    Approve
                  </button>
                </>
              )}
              {data?.latest_salary_submission?.status != "claim" && (
                <>
                  <Pill
                    isGreenDark={data.status == "approved"}
                    isRedDark={data.status == "rejected"}
                  >
                    {data?.latest_salary_submission?.status}
                  </Pill>
                </>
              )}
            </div>
          </div>
          <div className="right_cont">
            <div className="section">
              <p className="section_heading">Job Scope</p>
            </div>
            <div className="section">
              <p className="section_heading">Staff claim Note</p>
              <p className="note">
                {ReactHtmlParser(data?.latest_salary_submission?.summary)}
              </p>
            </div>
          </div>
        </div>
      </Card>
    </CustomModal>
  );
};

export default ClaimDetails;
