import React, { useMemo, useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import { useSelector } from "react-redux";
import {
  selectLaunchedProjects,
  selectUnderDevelopmentProjects,
} from "../../redux/projectSlice";
import { selectIsAdmin } from "../../redux/userSlice";
import { adminSideNav } from "./data";
import Theme from "../Theme";
import { categoriesTypesSelector } from "../../redux/categoriesTypesSlice";

const Sidebar = ({ className, onClose }) => {
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const udProjects = useSelector(selectUnderDevelopmentProjects);
  const launchedProject = useSelector(selectLaunchedProjects);
  // const isAdmin = useSelector(selectIsAdmin);
  const categorytypes = useSelector(categoriesTypesSelector);

  const navigation = useMemo(() => {
    let update = adminSideNav?.map((each) => {
      let eachUpdate = { ...each };
      if (each.title === "Categories") {
        let data = [
          {
            title: "Listing Types",
            url: "/categories/types",
          },
        ];
        categorytypes?.data?.forEach((each) => {
          data.push({
            title: each.title,
            url: `/categories/listing/${each.id}`,
          });
        });
        eachUpdate.dropdown = data;
      }
      return eachUpdate;
    });
    return update;
  }, [categorytypes]);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <NavLink
                className={styles.item}
                activeClassName={styles.active}
                to={x.url}
                key={index}
                exact
                onClick={onClose}
              >
                <Icon name={x.icon} size="24" />
                {x.title}
              </NavLink>
            ) : (
              <Dropdown
                ud_count={udProjects?.length || 0}
                launched_count={launchedProject?.length || 0}
                className={styles.dropdown}
                visibleSidebar={visible}
                setValue={setVisible}
                key={index}
                item={x}
                onClose={onClose}
              />
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
