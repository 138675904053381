import React, { useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon.js";
import Card from "../../../components/Card/index.js";
import CreateForm from "../CreateForm/CreateForm.jsx";


export const projectListGenereate = (projects) => {
  let title = projects?.map((each) => each.title || "");
  return title?.join(" , ");
};

const Table = ({
  tableData,
  loading,
  actions,
  viewClickHandler,
  deleteClickHanddler,
  options,
  createNewHandler,
  updateDataHandler,
  updateApiHandler,
  formFields,
  title,
  tableColumns
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const editClickHanddler = (data) => {
    setUpdateData(data);
    setShowCreateModal(true);
  };

  return (
    <>
      {showCreateModal && (
        <CreateForm
          show={showCreateModal}
          setShow={setShowCreateModal}
          updateData={updateData}
          setUpdateData={setUpdateData}
          formColumns={formFields}
          options={options}
          createNewHandler={createNewHandler}
          updateApiHandler={updateApiHandler}
          updateDataHandler={updateDataHandler}
          title={title}
        />
      )}
      <Card
        className={styles.card}
        title={title}
        classTitle="title-green"
        head={
          <button
            onClick={() => setShowCreateModal(true)}
            className={cn("button", styles.button)}
          >
            Add New
          </button>
        }
      >
        <div style={{ overflow: "auto" }}>
          <div className={styles.table}>
            <div className={styles.row}>
              {tableColumns?.map((x) => (
                <div className={styles.col}>{x.header}</div>
              ))}
            </div>
            {tableData?.map((x, index) => (
              <div className={styles.row} key={x.id}>
                {tableColumns.map((column) => (
                  <div className={styles.col}>
                    <div className={styles.label}>{column.header}</div>
                    {column.type === "image" && (
                      <img alt="" src={x?.[column?.accessor]} />
                    )}
                    <div>{x?.[column?.accessor]}</div>
                  </div>
                ))}
                {actions && (
                  <div className={styles.col}>
                    <div className={styles.label}></div>
                    <div className="icons_cont">
                      {deleteClickHanddler && (
                        <button
                          onClick={() => deleteClickHanddler(x)}
                          // disabled={!isAdmin}
                          className="action_icon trash"
                        >
                          <Icon name="trash" size="24" />{" "}
                        </button>
                      )}
                      {editClickHanddler && (
                        <button
                          // disabled={!isAdmin}
                          onClick={() => editClickHanddler(x)}
                          className="action_icon edit"
                        >
                          <Icon name="edit" size="24" />
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};

export default Table;
