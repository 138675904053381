export const serviceColumns = ["Id", "Title", "Rating", "cost", "Actions"];

export const MEDIA_TYPE_OPTIONS = [
  {
    label: "Image",
    value: "image",
  },
  {
    label: "YouTube URL",
    value: "youtube_url",
  },
];
