import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import ModalPreview from "../../../../../MyComponents/ModalPreview/index";
import Icon from "../../../../../components/Icon";
import ReactHtmlParser from 'react-html-parser';
import CustomImage from "../../../../../components/CustomImage/CustomImage";

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];


const Overview = ({ data }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);

  const features = data?.key_features?.split(',');
  return (
    <>
      <div className={styles.overview}>
        <div className={cn("h4", styles.title)}>{data?.title || ""}</div>
        <div className={styles.info}>{data?.domain_name || ""}</div>
        <div className={styles.line}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <img src="/images/content/avatar.jpg" alt="Avatar" />
            </div>
            by <span>Chelsie Haley</span>
          </div>
          <div className={styles.rating}>
            <Icon name="star-fill" size="24" />
            4.8
            <span className={styles.counter}>(87)</span>
          </div>
        </div>
        <div className={styles.gallery}>
          {gallery.map(
            (x, index) =>
              index < 1 && (
                <div className={styles.preview} key={index}>
                  <CustomImage src={data?.cover_image} />
                </div>
              )
          )}
          <button
            className={cn("button-white", styles.button)}
            onClick={() => setVisibleModalPreview(true)}
          >
            Show all preview
          </button>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-red", styles.subtitle)}>Overview</div>
            <div className={styles.content}>
              {ReactHtmlParser(data?.description || "")}
            </div>
          </div>
          <div className={styles.col}>
            <div className={cn("title-purple", styles.subtitle)}>Features</div>
            <ul className={styles.features}>
              {features?.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        gallery={[data?.cover_image]}
        title={data?.title}
        download
      />
    </>
  );
};

export default Overview;
