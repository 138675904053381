import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import { websiteAllowedOptions } from "./data";
import UseApiCalling from "../../../hooks/UseApiCalling";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import "./CreateForm.scss";
import SectionHeading from "../../../components/Card/SectionHeading";
import {
  categoriesTypesSelector,
  fetchAllCategoriesTypes,
  fetchAllCategoriesTypesWithCategories,
} from "../../../redux/categoriesTypesSlice";
import Editor from "../../../components/Editor";
import UseEditorState from "../../../hooks/UseEditorState";
import File from "../../../components/File";
import styles from "./ImagesAndCTA.module.sass";
import { fetchAllShops } from "../../../redux/ShopSlice";
import {
  categoriesSelector,
  fetchAllCategories,
} from "../../../redux/categoriesSlice";
import CategoryDropdown from "./CategoryDropdown";

const title = "Shop";
const CreateCategories = ({ show, setShow, updateData, setUpdateData }) => {
  const [values, setValues] = useState({
    description: null,
  });
  const [errors, setErrors] = useState({});
  const { isLoading, callApi } = UseApiCalling();
  const { generateHtml, generateState } = UseEditorState();
  const { data } = useSelector(categoriesTypesSelector);

  const closer = () => {
    setShow(false);
    setUpdateData(null);
  };

  const dispatch = useDispatch();
  const cb = (response, isSuccess) => {
    if (isSuccess) {
      setShow(false);
      dispatch(fetchAllShops(""));
      setValues({});
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("name", values?.name);
    formdata.append("description", generateHtml(values?.description));
    formdata.append("facebook", values?.facebook);
    formdata.append("instagram", values?.instagram);
    formdata.append("twitter", values?.twitter);
    if (values?.image && typeof values?.image !== "string")
      formdata.append("image", values?.image);
    let type_ids = [];
    data?.map((type) => {
      let selected_types = values[`type_${type?.id}`];
      selected_types?.forEach((type) => {
        type_ids.push(type.value);
      });
    });
    if (type_ids?.length) {
      formdata.append("category_ids", JSON.stringify(type_ids));
    }

    if (updateData) {
      formdata.append("_method", "PUT");
      callApi(`/shops/${updateData.id}`, formdata, "post", cb);
    } else {
      callApi("/shops", formdata, "post", cb);
    }
  };

  useEffect(() => {
    if (updateData && data) {
      let update = {
        name: updateData.name,
        description: generateState(updateData.description),
        image: updateData.image,
        facebook: updateData.facebook,
        instagram: updateData.instagram,
        twitter: updateData.twitter,
      };
      updateData?.categories?.map((each) => {
        if (!update[`type_${each.type_id}`])
          update[`type_${each.type_id}`] = [
            { label: each.title, value: each?.id },
          ];
        else
          update[`type_${each.type_id}`].push({
            label: each.title,
            value: each?.id,
          });
      });
      setValues(update);
    }
  }, [updateData, setUpdateData, data]);

  useEffect(() => {
    return () => {
      setUpdateData(null);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAllCategoriesTypesWithCategories());
    // dispatch(fetchAllCategories())
  }, []);

  console.log(data, "categoryTypes");
  console.log(updateData, "updatedData");

  return (
    <Modal outerClassName="modal_full" visible={show} onClose={closer}>
      {isLoading && <Loader className="page_loader" />}
      <SectionHeading
        title={updateData ? `Update ${title}` : `Add ${title}`}
        classTitle="title-blue"
      />
      <form onSubmit={formSubmitHandler}>
        <TextInput
          values={values}
          setValues={setValues}
          label="Title"
          name="name"
          type="text"
          required
          errors={errors}
          setErrors={setErrors}
        />
        <div className={`${styles.images} mt-20`}>
          <File
            className={styles.field}
            title="Click or drop image"
            label="Shop Image"
            tooltip=""
            values={values}
            setValues={setValues}
            name="image"
          />
        </div>
        {data?.map((each) => (
          <CategoryDropdown
            values={values}
            setValues={setValues}
            label={`Category ${each.title}`}
            options_data={each?.categories || []}
            name={`type_${each.id}`}
          />
        ))}
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Facebook"
            name="facebook"
            type="text"
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Twitter"
            name="twitter"
            type="text"
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <div className="mt-20">
          <TextInput
            values={values}
            setValues={setValues}
            label="Instagram"
            name="instagram"
            type="text"
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <div className="mt-20">
          <Editor
            label="Description"
            name={"description"}
            values={values}
            setValues={setValues}
          />
        </div>

        <button type="submit" className={"button mt-30"}>
          {updateData ? `Update ${title}` : `Create ${title}`}
        </button>
      </form>
    </Modal>
  );
};

export default CreateCategories;
