import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { useDispatch, useSelector } from "react-redux";
import { signInUser } from "../../redux/userSlice";
import Loader from "../../components/Loader";




const SignIn = () => {
  const heightWindow = use100vh();
  const [values, setValues] = useState({
    email: 'admin@gmail.com',
    password: "admin1234"
  });
  const [errors, setErrors] = useState({});
  const { isLoading } = useSelector(store => store.user)

  const dispatch = useDispatch()
  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(signInUser(values));
  }




  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      {isLoading && <Loader className="page_loader" />}
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-dark.png"
            srcDark="/images/logo-light.png"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        {/* <div className={styles.head}>
          <div className={styles.subtitle}>Sign up with Open account</div>
          <div className={styles.btns}>
            <button onClick={googleLogin} className={cn("button-stroke", styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button>
          </div>
        </div> */}
        <div className={styles.body}>
          <div className={styles.subtitle}>Or continue with email address</div>
          <form onSubmit={formSubmitHandler}>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
            <button className={cn("button", styles.button)}>Sign in</button>

          </form>

          {/* <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/sign-up">
              Sign up
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
