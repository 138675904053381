import React, { useState } from "react";
import Axios from "../redux/axiosInstance";
import { toast } from "react-toastify";

const UseApiCalling = () => {
  const [isLoading, setIsLoading] = useState(false);
  const callApi = (
    route,
    payload,
    method,
    afterResponseCb,
    showToast = true,
    successMsg = "Done successfully",
    failureMsg = "Something went wrong, try again later"
  ) => {
    setIsLoading(true)
    return Axios[method](route, payload)
      .then((res) => {
        setIsLoading(false);
        afterResponseCb(res?.data, true);
        if (res.data.success) {
          showToast &&
            toast.success(
              res?.data?.message || successMsg 
            );
        } else {
          showToast &&
            toast.error(
              res?.data?.message ||
                failureMsg 
            );
        }
      })
      .catch((e) => {
        afterResponseCb(e?.response, false);
        setIsLoading(false);
        toast.error(
          e?.response?.data?.message ||
            failureMsg 
        );
      });
  };
  return {
    isLoading,
    callApi,
  };
};

export default UseApiCalling;
