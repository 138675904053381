import React, { useEffect, useMemo, useState } from "react";
import Editor from "../../components/Editor";
import { getRecentMonthOptions } from "../../utils";
import { stateToHTML } from "draft-js-export-html";
import Axios from "../../redux/axiosInstance";
import { toast } from "react-toastify";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import TextInput from "../../components/TextInput";
import Card from "../../components/Card";
import styles from "./ModalGaSubmission.module.sass";
import Loader from "../../components/Loader";
import { ContentState, EditorState, convertFromHTML } from "draft-js";

const GASubmitForm = ({ project, setActiveIndex, editData }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const GaSubmissionHandler = (e) => {
    e?.preventDefault();
    let payload = {
      ...values,
    };
    delete payload.summary;
    delete payload.month;
    const contentState = values?.summary?.getCurrentContent();
    let summaryHtml = "";
    if (contentState) summaryHtml = stateToHTML(contentState);
    payload.summary = summaryHtml;
    payload.month = values?.month?.value;
    payload.project_id = project?.id;
    setLoading(true);

    (editData
      ? Axios.put(`/gaSubmissions/${editData.id}`, payload)
      : Axios.post("/gaSubmissions", payload))
          .then((res) => {
            if (res?.data?.success) {
              setActiveIndex(1);
              toast.success(res?.data?.message || "GA submitted successfully");
            } else {
              toast.error(res?.data?.message || "Failed to submit GA");
            }
          })
          .catch((e) => {
            toast.error(
              e?.response?.data?.message || "Something went wrong Try again..."
            );
          })
          .finally(() => {
            setLoading(false);
          });
  };
  const monthOptions = useMemo(getRecentMonthOptions, []);

  useEffect(() => {
    if (editData) {
      console.log(editData, "some values");
      const monthOption = monthOptions?.find(
        (option) => option.value === editData.month
      );
      if (!monthOption) {
      }
      let updateValues = { ...editData };
      updateValues.month = monthOption;
      const contentBlocks = convertFromHTML(updateValues.summary);
      const contentState = ContentState.createFromBlockArray(contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      updateValues.summary = editorState;
      setValues(updateValues);
    }
  }, [editData]);

  return (
    <form onSubmit={GaSubmissionHandler}>
      {loading && <Loader className="page_loader" />}
      <Card
        title={editData ? "Update GA Submission" : "GA Submission"}
        classTitle="title-green"
      >
        <div className="mb-20">
          <CustomDropdown
            label="Month"
            values={values}
            setValues={setValues}
            options={monthOptions}
            name="month"
            placeholder="Select Month"
            isClearable
            required
          />
        </div>
        <div className={styles.form_grid}>
          <TextInput
            values={values}
            setValues={setValues}
            label="Users"
            name="users"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="New Users"
            name="new_users"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Average Enagagement Time"
            name="average_engagement_time"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Enagagement Rate"
            name="engagement_rate"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Session"
            name="session"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Mobile User%"
            name="mobile_user_percent"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
          <TextInput
            values={values}
            setValues={setValues}
            label="Desktop User%"
            name="desktop_user_percent"
            type="number"
            required
            errors={errors}
            setErrors={setErrors}
            className={styles.field}
          />
        </div>
      </Card>
      <Card title="Country Audience" classTitle="title-green">
        <div className={`${styles.form_grid} mb-20`}>
          {project?.countries?.split(",")?.map((country) => (
            <TextInput
              values={values}
              setValues={setValues}
              label={`${country} User %`}
              name={`${country}_percentage`}
              type="number"
              required
              errors={errors}
              setErrors={setErrors}
              className={styles.field}
            />
          ))}
        </div>
        <Editor
          label="Message to reviewer"
          tooltip="Description"
          name={"summary"}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
        />
        <button type="submit" className="button mt-20">
          {editData ? "Update GA" : "Submit GA"}
        </button>
      </Card>
    </form>
  );
};

export default GASubmitForm;
