import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./CustomModal.module.sass";
import Panel from "./Panel";

const CustomModal = ({ visible, onClose, title, children }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  


  return createPortal(
    visible && (
      <div  className={styles.modal}>
        <div className={styles.outer}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <Panel title={title} onClose={onClose} />
            {children}
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default CustomModal;
