import React, { useMemo, useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Balance from "../../../../components/Balance";
import { agoCalculator, divide } from "../../../../utils";
import UserComponent from "./UserComponent/UserComponent";
import { roleToCPoints } from "../../../NewProject/TeamsSection/data";

const Row = ({
  item,
  value,
  onChange,
  activeTable,
  setActiveTable,
  activeData,
  setActiveData,
}) => {
  const handleClick = (data) => {
    setActiveTable(true);
    setActiveData(data);
  };

  const cpoints = useMemo(() => {
    let project_teams = item?.project_teams;
    let sum = 0;
    project_teams?.forEach((each, i) => {
      sum += roleToCPoints[each?.role_id] || 0;
    });
    return sum;
  }, [item]);
  const salary = useMemo(() => {
    return item?.salary_submissions_avg_claim_amount;
  }, [item]);

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeData?.id === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          {/* <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          /> */}
        </div>
        <div className={styles.col}>
          <div className={styles.item} onClick={() => handleClick(item)}>
            <UserComponent
              avatar={item.avatar}
              description={item.position}
              name={item.name}
            />
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{agoCalculator(item?.created_at)}</div>
        </div>
        <div key="cpoints" className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>{cpoints || 0}</div>
          </div>
        </div>
        <div key="salary" className={styles.col}>
          <div className={cn("status-green-dark", styles.purchase)}>
            ${salary || 0}
          </div>
        </div>
        <div key="traffic" className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>
              {item.ga_submissions_sum_users || 0}
            </div>
          </div>
        </div>
        <div key="reg." className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>
              {item.aflliate_reports_sum_registrations || 0}
            </div>
            {/* <Balance className={styles.balance} value={item.balance || 0} /> */}
          </div>
        </div>
        <div key="CPC" className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>{divide(salary, cpoints, true)}</div>
          </div>
        </div>
        <div key="CPR" className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>
              {divide(
                item?.salary_submissions_sum_claim_amount,
                item.aflliate_reports_sum_registrations,
                true
              )}
            </div>
          </div>
        </div>
        <div key="CPS" className={styles.col}>
          <div className={styles.lifetime}>
            <div className={styles.price}>
              {divide(
                item?.salary_submissions_sum_claim_amount,
                item.ga_submissions_sum_users,
                true
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
