import React from "react";
import { SHOP_SECTIONS } from "./data";

const ShopSections = () => {
  return (
    <div>
      <p className="description">
        Sections are Just Images of particular shop Images Can be grouped by section.
        <br />
        Currently Assuming these 3 sections this Can be easily Add or modify. Backend can support.
      </p>
      <ol className="mt-20">
        {SHOP_SECTIONS?.map((each,i) => (
          <li>{i+1}{". "}{each}</li>
        ))}
      </ol>
    </div>
  );
};

export default ShopSections;
