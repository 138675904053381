import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal";
import TextInput from "../../../components/TextInput";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { websiteAllowedOptions } from "./data";
import UseApiCalling from "../../../hooks/UseApiCalling";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import "./CreateCategories.scss";
import SectionHeading from "../../../components/Card/SectionHeading";
import {
  categoriesTypesSelector,
  fetchAllCategoriesTypes,
} from "../../../redux/categoriesTypesSlice";
import { fetchAllCategories } from "../../../redux/categoriesSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CreateCategories = ({ show, setShow, updateData, setUpdateData }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const { isLoading, callApi } = UseApiCalling();

  const closer = () => {
    setShow(false);
  };

  const dispatch = useDispatch();
  const cb = (response, isSuccess) => {
    if (isSuccess) {
      setShow(false);
      dispatch(fetchAllCategories(""));
      setValues({});
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    let payload = { ...values };
    delete payload.type_id;
    payload.type_id = values?.type_id?.value;
    if (updateData) {
      callApi(`/categories/${updateData.id}`, payload, "put", cb);
    } else {
      callApi("/categories", payload, "post", cb);
    }
  };



  useEffect(() => {
    dispatch(fetchAllCategoriesTypes());
    return () => {
      setUpdateData(null);
    };
  }, []);

  const categoryTypes = useSelector(categoriesTypesSelector);

  const categoryTypesOptions = useMemo(() => {
    const options = categoryTypes?.data?.map((each) => ({
      label: each?.title,
      value: each.id,
    }));
    return options;
  }, [categoryTypes]);

  const { type } = useParams();
  useEffect(() => {
    if (type && categoryTypesOptions?.length&&!updateData) {
      let value = categoryTypesOptions?.find((each) => each.value == type);
      setValues({ type_id: value });
    }
  }, [categoryTypesOptions]);

  useEffect(() => {
    if (updateData) {
      let update = { ...updateData };
      update.type_id = categoryTypesOptions?.find(
        (each) => each.value == updateData.type_id
      );
      setValues(update);
    }

  }, [updateData, setUpdateData,categoryTypesOptions]);
  
  return (
    <Modal outerClassName="modal_full" visible={show} onClose={closer}>
      {isLoading && <Loader className="page_loader" />}
      <SectionHeading
        title={updateData ? "Update Category" : "Add Category"}
        classTitle="title-blue"
      />
      <form onSubmit={formSubmitHandler}>
        <div className="form_grid_2_cols">
          <TextInput
            values={values}
            setValues={setValues}
            label="Title"
            name="title"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />

          <CustomDropdown
            name="type_id"
            options={categoryTypesOptions}
            label="Category Type"
            values={values}
            setValues={setValues}
            isClearable
            errors={errors}
            setErrors={setErrors}
          />
        </div>

        <button type="submit" className={"button mt-30"}>
          {updateData ? "Update Category" : "Create Category"}
        </button>
      </form>
    </Modal>
  );
};

export default CreateCategories;
