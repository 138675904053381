import React, { useState } from "react";
import cn from "classnames";
import styles from "./Head.module.sass";
import Icon from "../../../../components/Icon";
import Axios from "../../../../redux/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import { fetchAllStaff } from "../../../../redux/staffSlice";

const Head = ({ className, activeData, setActiveData,onClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const DeleteClickHandler = () => {
    if (window.confirm("Are you sure you want to delete")) {
      setLoading(true);
      Axios.delete(`/user-delete/${activeData.id}`)
        .then((res) => {
          setLoading(false);
          toast.success(res?.data?.message || "User deleted successfully");
          setActiveData(null);
          onClose()
          dispatch(fetchAllStaff());
        })
        .catch((e) => {
          setLoading(false);
          toast.error(
            e?.response?.data?.message || "Failed to delete the user"
          );
        });
    }
  };
  return (
    <>
      {loading && <Loader className="page_loader" />}
      <div className={cn(styles.head, className)}>
        <div className={styles.user}>
          <div className={styles.avatar}>
            <img src="/images/content/avatar-1.jpg" alt="Avatar" />
          </div>
          <div className={styles.details}>
            <div className={styles.man}>{activeData?.name || ""}</div>
            <div className={styles.login}>{activeData?.position || ""}</div>
          </div>
        </div>
        <div className={styles.btns}>
          <button
            onClick={DeleteClickHandler}
            className={cn("button", styles.button)}
          >
            <span>Delete User</span>
            <Icon name="trash" size="24" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Head;
