import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import { agoCalculator } from "../../../../utils";

const Item = ({ className, item }) => {

  return (
    <>
      <div className={cn(styles.item, className)}>
        <div className={styles.avatar}>
          <img src="/images/content/avatar.jpg" alt="Avatar" />
        </div>
        <div className={styles.details}>
          <div className={styles.line}>
            <div className={styles.author}>{item?.user?.name || ""}</div>
            <div className={styles.time}>{agoCalculator(item?.created_at)}</div>
            <div className={styles.rating}>
              {(item.rating_count || 0)?.toFixed(1)}
              <Icon name="star-fill" size="24" />
            </div>
          </div>
          <div className={styles.login}>{item?.user?.email}</div>
          <div
            className={styles.comment}
            // dangerouslySetInnerHTML={{ __html: item.comment }}
          >
            {item?.comment_description}
          </div>
          {/* <Control
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
          /> */}
        </div>
      </div>
      {/* {item.answer && (
        <div className={styles.list}>
          {item.answer.map((x, index) => (
            <div className={styles.answer} key={index}>
              <div className={styles.avatar}>
                <img src={x.avatar} alt="Avatar" />
              </div>
              <div className={styles.details}>
                <div className={styles.line}>
                  <div className={styles.author}>{x.author}</div>
                  <div className={styles.time}>{x.time}</div>
                  <div className={styles.rating}>
                    {x?.rating?.toFixed(1)}
                    <Icon name="star-fill" size="24" />
                  </div>
                </div>
                <div
                  className={styles.comment}
                  dangerouslySetInnerHTML={{ __html: x.comment }}
                ></div>
                <Control
                  valueAnswer={currentValueAnswer}
                  setValueAnswer={setCurrentValueAnswer}
                />
              </div>
            </div>
          ))}
        </div>
      )} */}
    </>
  );
};

export default Item;
