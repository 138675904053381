import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  isLoading: false,
  detail: null,
  error: null,
  manager_projects: null,
};

export const fetchAllProjects = createAsyncThunk(
  "fetchProjects",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/projects${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchProject = createAsyncThunk(
  "fetchProject",
  (id, { rejectWithValue }) => {
    return Axios.get(`/projects/${id}`).then((res) => res.data);
  }
);
export const fetchManagerProjects = createAsyncThunk(
  "manager_projects",
  (payload, { rejectWithValue }) => {
    return Axios.get(`my_manager_projects`).then((res) => res.data);
  }
);

export const projectsSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDetailProjectData: (state) => {
      state.detail = null;
    },
    clearAllProjectsError: (state, action) => {
      state.error = null;
    },
    setManagerProject: (state, action) => {
      state.manager_projects = action.payload;
    },
    setDetailProject: (state, action) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllProjects.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllProjects.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE Project ////////////////
    builder.addCase(fetchProject.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchProject.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    //////////// FETCH Manager Projects ////////////////
    builder.addCase(fetchManagerProjects.pending, (state) => {
      if (!state.manager_projects) state.isLoading = true;
    });
    builder.addCase(fetchManagerProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.manager_projects = action.payload?.data;
    });
    builder.addCase(fetchManagerProjects.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default projectsSlice.reducer;

/// SELECTORS
export const projectSelector = (store) => store.projects;

export const ProjectSlectorWithAflliateAndGaGroup = (store) => {
  let projects = store?.projects?.data;
  const formattedProjects = projects?.map((each) => {
    let project = { ...each };
    let affliates = project.affliates;
    let ga_submissions = project.ga_submissions;
    const result = groupByReportMonth(affliates, ga_submissions);
    project.group_report_month = result;
    return project;
  });
  return formattedProjects;
};

export const selectUnderDevelopmentProjects = (store) => {
  let udProjects = store.projects?.data?.filter(
    (each) => each?.is_project_launched != "true"
  );
  return udProjects;
};

export const selectLaunchedProjects = (store) => {
  let launchedProjects = store.projects?.data?.filter(
    (each) => each.is_project_launched == "true"
  );
  return launchedProjects;
};

export const {
  clearAllProjectsError,
  clearDetailProjectData,
  setDetailProject,
  setManagerProject,
} = projectsSlice.actions;

const groupByReportMonth = (affiliates, ga_submissions) => {

  const result = [];
  affiliates.forEach((affiliate) => {
    const report_month = affiliate.report_month;
    const ga_submission = ga_submissions.find(
      (submission) => submission.month === report_month
    );
    result.push({
      report_month: report_month,
      affiliate: affiliate,
      ga_submission: ga_submission || null,
    });
  });
  ga_submissions.forEach((submission) => {
    const report_month = submission.month;
    const affiliate = affiliates.find(
      (affiliate) => affiliate.report_month === report_month
    );
    if (!affiliate) {
      result.push({
        report_month: report_month,
        affiliate: null,
        ga_submission: submission,
      });
    }
  });

  return result;
};
