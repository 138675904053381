export const UDP_TYPE = "UDP_TYPE";
export const LAUNCHED_TYPE = "LAUNCHED_TYPE";

export const adminSideNav = [
  {
    title: "Categories",
    slug: "categories",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Listing Types",
        url: "/categories/types",
      },
    ],
  },
  {
    title: "Shops",
    slug: "shop",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Sections",
        url: "/shop/sections",
      },
      {
        title: "Shop Listing",
        url: "/shop/listing",
      },
    ],
  },
  {
    title: "Promotions",
    slug: "promotion",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Promotions Listing",
        url: "/promotion/listing",
      },
    ],
  },
  {
    title: "News",
    slug: "news",
    icon: "pie-chart",
    dropdown: [
      {
        title: "News Listing",
        url: "/news/listing",
      },
    ],
  },
  {
    title: "Luckydraw",
    slug: "luckydraw",
    icon: "pie-chart",
    dropdown: [
      {
        title: "Luckydraw Listing",
        url: "/luckydraw/listing",
      },
    ],
  },
];
