import React, { useState } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import Control from "./Control";
import Icon from "../../components/Icon";
import CustomImage from "../../components/CustomImage/CustomImage";
import { DEFAULT_PROJECT_IMAGE } from "../../components/Table/Row";
import { dateFormatter } from "../../utils";

const Project = ({
  className,
  item,
  onChange,
  released,
}) => {

  return (
    <div
      className={cn(styles.product, className)}
    >
      <div className={styles.preview}>
 
        <Control data={item} className={styles.control} />
        {
          item?.cover_image ? <CustomImage src={item.cover_image} alt="Project" />
            : <img alt="Project" src={DEFAULT_PROJECT_IMAGE} />
        }

      </div>
      <div className={styles.line}>
        <div className={styles.title}>{item.title}</div>
        {item.price > 0 ? (
          <div className={styles.price}>${item.price || "0"}</div>
        ) : (
          <div className={styles.empty}>${item.price || "0"}</div>
        )}
      </div>
      {released && (
        <div className={styles.date}>
          <Icon name="clock" size="24" /> {dateFormatter(item.updated_at)}
        </div>
      )}
      <div className="align-center justify-between">
        <div className="pill blue">
          Manager
        </div>
        {/* {item.ratingValue ? (
          <div className={styles.rating}>
            <Icon name="star-fill" size="24" />
            {item.ratingValue}{" "}
            <span className={styles.counter}>({item.ratingCounter})</span>
          </div>
        ) : (
          <div className={cn(styles.rating, styles.ratingEmpty)}>
            <Icon name="star-stroke" size="24" />
            No ratings
          </div>
        )} */}
      </div>

    </div>
  );
};

export default Project;
