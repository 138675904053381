
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from './axiosInstance';

const initialState = {
    data: null,
    isLoading: false,
    detail: null,
    error: null
}

export const fetchAllSalaryClaims = createAsyncThunk('/api/salary-submissions', (queryString, { rejectWithValue }) => {
    return Axios.get(`/salary-submissions`).then(res => res.data)
});


export const salaryClaimSlice = createSlice({
    name: 'salaryClaim',
    initialState,
    reducers: {
        clearDetailSalaryClaimError: (state) => {
            state.detail = null;
        },
        clearAllSalaryClaimError: (state, action) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllSalaryClaims.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchAllSalaryClaims.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload?.data;
        })
        builder.addCase(fetchAllSalaryClaims.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.error.message;
        })


    }
})

export default salaryClaimSlice.reducer;

/// SELECTORS


export const selectSalaryClaims = (store)=>store.salaryClaims

export const { clearAllSalaryClaimError, clearDetailSalaryClaimError } = salaryClaimSlice.actions;



