import React from "react";
import cn from "classnames";
import styles from "./Comments.module.sass";
import Item from "./Item";



const Comments = ({ className ,data}) => {

  return (
    <>
      <div className={cn(styles.comments, className)}>
        <div className={styles.list}>
          {data?.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
     
    </>
  );
};

export default Comments;
