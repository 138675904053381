import React, { useEffect, useMemo, useState } from "react";
import styles from "./DailyAfflaiteForm.module.sass";
import TextInput from "../../../components/TextInput";
import UseApiCalling from "../../../hooks/UseApiCalling";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const DailyAfflliateForm = ({ project, editData }) => {
  const [values, setValues] = useState({});
  const { isLoading, callApi } = UseApiCalling();
  const { isLoading: isLoading2, callApi: callApi2 } = UseApiCalling();

  const formInputs = useMemo(() => {
    return generateMonthlyDays();
  }, []);

  console.log(formInputs, "soem form inputs");
  const cb = (response, isSuccess) => {
    if (isSuccess) {
      toast.success(response.message);
    }
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();
    console.log(values, "some values");
    let records = Object.keys(values)?.map((day) => {
      let registrations = values[day];
      let record = {
        month_year: `${formInputs[0]?.month_year}-01`,
        day,
        registrations,
      };
      return record;
    });
    let payload = {
      records,
      project_id: project?.id,
    };
    callApi(`/daily-registrations`, payload, "post", cb);
    console.log(records);
  };
  //'month_year', 'day', 'registrations', 'user_id', 'project_id'
  const cb2 = (response, isSuccess) => {
    console.log(response);
    let data = response.data;
    let format = {};
    data?.forEach((each) => {
      format[each.day] = each.registrations;
    });
    setValues(format);
  };
  useEffect(() => {
    let month_year = formInputs[0]?.month_year+"-01";
    if (editData) {
      month_year = editData.report_month;
    }
    let url = `/daily-registrations?project_id=${project?.id}&&month_year=${month_year}`;
    callApi(url, null, "get", cb2,false);
  }, []);
  return (
    <div className="p-20">
      {isLoading && <Loader />}
      <h2 className="mb-20">Daily Affliate Registrations</h2>
      <form onSubmit={formSubmitHandler}>
        <div className={styles.form_inputs_grid}>
          {formInputs?.map((each) => (
            <div
              className={`${styles.input_cont} ${
                !each.past_date && styles.disabled
              }`}
            >
              <p className="label">Day {each.day}</p>
              <TextInput
                values={values}
                setValues={setValues}
                name={each.day}
                disabled={!each.past_date}
              />
            </div>
          ))}
        </div>
        <button className="button mt-20">Save Daily Registrations</button>
      </form>
    </div>
  );
};

export default DailyAfflliateForm;

// need to generate the monthly days like this based on current month
const format = [
  {
    month_year: "2024-3",
    day: 1,
    past_date: true, // true or false based on its past_date or not
  },
  {
    month_year: "2024-3",
    day: 2,
    past_date: false, // true or false based on its past_date or not
  },
  // ... upto current month days
];

const generateMonthlyDays = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index

  const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

  const monthlyDays = [];

  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(currentYear, currentMonth - 1, i);
    const pastDate = date < currentDate;
    monthlyDays.push({
      month_year: `${currentYear}-${currentMonth}`,
      day: i,
      past_date: pastDate,
    });
  }

  return monthlyDays;
};
