import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./styles/app.sass";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from "./Routes";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {  fetchAllCategoriesTypes } from "./redux/categoriesTypesSlice";

function App() {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchAllCategoriesTypes());
  }, []);

  return (
    < >
      <ToastContainer />
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </>
  );
}

export default App;
