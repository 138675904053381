import React, { useState } from "react";
import Card from "../../../components/Card";
import styles from "./ShopDetailPage.module.sass";
import cn from "classnames";
import TextInput from "../../../components/TextInput";
import { serviceColumns } from "./data";
import Icon from "../../../components/Icon";
import UseApiCalling from "../../../hooks/UseApiCalling";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchShop } from "../../../redux/ShopSlice";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../components/Loader";
import { FaEye } from "react-icons/fa";

const Services = ({ services }) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { isLoading, callApi } = UseApiCalling();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const { id } = useParams();

  const dispatch = useDispatch();
  const cb = (response, isSuccess) => {
    if (isSuccess) {
      dispatch(fetchShop(id));
      setValues({});
    }
  };
  const formSubmitHandler = (e) => {
    e?.preventDefault();
    let payload = {
      ...values,
      shop_id: id,
    };
    if (updateData) {
      callApi(`/shop-service/${updateData.id}`, payload, "put", cb);
    } else {
      callApi("/shop-service", payload, "post", cb);
    }
  };

  const deleteClickHanddler = (data) => {
    if (window.confirm(`Are you sure to delete this Service "${data?.title}"`))
      callApi(`/shop-service/${data?.id}`, null, "delete", cb);
  };
  const editClickHanddler = (data) => {
    setUpdateData(data);
    setValues(data);
    setShowCreateForm(true);
  };

  const history = useHistory()
  const viewDetailHandler = (x) =>{
    history.push(`/services/${x?.id}`)
  }

  const cancelClickHandler = () => {
    setUpdateData(null);
    setShowCreateForm(false);
  };

  return (
    <Card
      head={
        <button
          onClick={() => setShowCreateForm((state) => !state)}
          className={cn("button", styles.button)}
        >
          {!showCreateForm ? "Show Add New Service" : "Hide Add New Service"}
        </button>
      }
      className={styles.card}
      title="Services"
      classTitle="title-blue"
    >
      {isLoading && <Loader />}
      {showCreateForm && (
        <div>
          <form className="mt-20" onSubmit={formSubmitHandler}>
            <TextInput
              values={values}
              setValues={setValues}
              label="Title *"
              name="title"
              type="text"
              required
              errors={errors}
              setErrors={setErrors}
              className="mb-20"
            />
            <TextInput
              values={values}
              setValues={setValues}
              label="Description"
              name="description"
              type="text"
              errors={errors}
              setErrors={setErrors}
              className="mb-20"
            />
            <div className={styles.form_grid}>
              <TextInput
                values={values}
                setValues={setValues}
                label="Cost *"
                name="cost"
                type="number"
                required
                errors={errors}
                setErrors={setErrors}
              />
              <TextInput
                values={values}
                setValues={setValues}
                label="Rating "
                name="rating"
                type="number"
                required
                errors={errors}
                setErrors={setErrors}
              />
            </div>
            <div className="flex gap-20 mt-20 mb-20">
              <button className={cn("button", styles.button, "")}>
                {updateData ? "Update" : "Submit"}
              </button>
              <button
                onClick={cancelClickHandler}
                type="button"
                className="button outline-btn"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <div style={{ height: "40px" }}></div>
      <div>
        <div className={styles.table}>
          <div className={styles.row}>
            {serviceColumns?.map((x) => (
              <div className={styles.col}>{x}</div>
            ))}
          </div>
          {services?.map((x, index) => (
            <div className={styles.row} key={x.id}>
              <div className={styles.col}>
                <div className={styles.label}>{serviceColumns[0]}</div>
                <div>{x?.id || ""}</div>
              </div>

              <div className={styles.col}>
                <div className={styles.label}>{serviceColumns[1]}</div>
                <div>{x?.title}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serviceColumns[2]}</div>
                <div>{x?.rating}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serviceColumns[3]}</div>
                <div>${x?.cost}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}></div>
                <div className="icons_cont">
                  <button
                    onClick={() => deleteClickHanddler(x)}
                    // disabled={!isAdmin}
                    className="action_icon trash"
                  >
                    <Icon name="trash" size="24" />{" "}
                  </button>
                  <button
                    // disabled={!isAdmin}
                    onClick={() => editClickHanddler(x)}
                    className="action_icon edit"
                  >
                    <Icon name="edit" size="24" />
                  </button>
                  <button
                    // disabled={!isAdmin}
                    onClick={() => viewDetailHandler(x)}
                    className="action_icon edit"
                  >
                    <FaEye size={24} />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Services;
