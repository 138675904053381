import React, { useEffect, useMemo, useState } from "react";
import { getRecentMonthOptions } from "../../utils";
import Axios from "../../redux/axiosInstance";
import { toast } from "react-toastify";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import TextInput from "../../components/TextInput";
import Card from "../../components/Card";
import styles from "./ModalAffliateSubmission.module.sass";
import Loader from "../../components/Loader";
import DailyAfflliateForm from "./DailyAffliateForm/DailyAfflliateForm";
const colors = [
  "title-blue",
  "title-red",
  "title-purple",
  "title-yellow",
  "title-green",
];

const AffliateSubmitForm = ({ project, setActiveIndex, editData }) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const GaSubmissionHandler = (e) => {
    e?.preventDefault();
    let payload = {
      ...values,
    };
    delete payload.report_month;

    payload.report_month = values?.report_month?.value;
    payload.project_id = project?.id;

    let country_affliates = Object.keys(countryValues)?.map((country) => {
      let country_data = countryValues[country];
      country_data.country = country;
      return country_data;
    });
    payload.country_affliates = country_affliates;
    setLoading(true);
    (editData
      ? Axios.put(`/aflliate/${editData.id}`, payload)
      : Axios.post("/aflliate", payload)
    )
      .then((res) => {
        if (res?.data?.success) {
          setActiveIndex(1);
          toast.success(
            res?.data?.message || "Afflaite Report submitted successfully"
          );
        } else {
          toast.error(res?.data?.message || "Failed to submit Affliate Report");
        }
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.message || "Something went wrong Try again..."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const monthOptions = useMemo(getRecentMonthOptions, []);

  useEffect(() => {
    if (editData) {
      const monthOption = monthOptions?.find(
        (option) => option.value === editData.report_month
      );
      if (!monthOption) {
      }
      let updateValues = { ...editData };
      delete updateValues.country_affliates;
      updateValues.report_month = monthOption;
      setValues(updateValues);
      let countryUpdateValues = {};
      editData.country_affliates?.map((each) => {
        countryUpdateValues[each.country] = { ...each };
      });
      setCountryValues(countryUpdateValues);
    }
  }, [editData]);

  const [countryValues, setCountryValues] = useState({});
  const countryInputsChangeHandler = (e, country) => {
    console.log(e, "soem e");
    let name = e.target.name;
    let value = e.target.value;
    let countrydata = { ...(countryValues[country] || {}), [name]: value };
    setCountryValues({ ...countryValues, [country]: countrydata });
  };
  console.log(countryValues, "some country values");
  console.log(values, "values");

  const [showDaily, setShowDaily] = useState(false);
  return (
    <>
      <div className={styles.daily_affilate_btn}>
        <button
          onClick={() => setShowDaily((state) => !state)}
          className="button"
        >
          {
            !showDaily ? "Show Daily Regi. Submission" : "Hide Daily Regi. Form"
          }
          
        </button>
      </div>
      {showDaily && <DailyAfflliateForm project={project} editData={editData}/>}

      <form onSubmit={GaSubmissionHandler}>
        {loading && <Loader className="page_loader" />}

        <Card title={"Bitly and Report Month"} classTitle="title-green">
          <div className="flex gap-20">
            <div className="flex-1">
              <CustomDropdown
                label="Report Month"
                values={values}
                setValues={setValues}
                options={monthOptions}
                name="report_month"
                placeholder="Select Month"
                isClearable
                required
              />
            </div>

            <TextInput
              values={values}
              setValues={setValues}
              label="Total Bitly Clicks"
              name="bitly_count"
              type="number"
              required
              errors={errors}
              setErrors={setErrors}
              className={`${styles.field} flex-1`}
            />
          </div>
        </Card>
        <Card title="Affliate Account Data" classTitle="title-green">
          <div className={styles.form_grid}>
            <TextInput
              values={values}
              setValues={setValues}
              label="Total Registrations"
              name="registrations"
              type="number"
              required
              errors={errors}
              setErrors={setErrors}
              className={styles.field}
            />
            <TextInput
              values={values}
              setValues={setValues}
              label="Total FDU"
              name="fdu"
              type="number"
              required
              errors={errors}
              setErrors={setErrors}
              className={styles.field}
            />

            <TextInput
              values={values}
              setValues={setValues}
              label="Total Monthly Deposits"
              name="deposits"
              type="number"
              required
              errors={errors}
              setErrors={setErrors}
              className={styles.field}
            />
          </div>
          <div className={` mb-20`}>
            {project?.countries?.split(",")?.map((country, i) => (
              <Card title={country} classTitle={colors[i % 2]}>
                <div className={styles.form_grid}>
                  <TextInput
                    values={countryValues[country]}
                    setValues={setValues}
                    label="Registrations"
                    name="country_registrations"
                    type="number"
                    required
                    errors={errors}
                    setErrors={setErrors}
                    className={styles.field}
                    onChange={(e) => countryInputsChangeHandler(e, country)}
                  />
                  <TextInput
                    values={countryValues[country]}
                    setValues={setValues}
                    label="FDU"
                    name="country_fdu"
                    type="number"
                    required
                    errors={errors}
                    setErrors={setErrors}
                    className={styles.field}
                    onChange={(e) => countryInputsChangeHandler(e, country)}
                  />

                  <TextInput
                    values={countryValues[country]}
                    setValues={setValues}
                    label="Monthly Deposits"
                    name="country_deposits"
                    type="number"
                    required
                    errors={errors}
                    setErrors={setErrors}
                    className={styles.field}
                    onChange={(e) => countryInputsChangeHandler(e, country)}
                  />
                </div>
              </Card>
            ))}
          </div>

          <button type="submit" className="button mt-20">
            {editData ? "Update Affliate Report" : "Submit Affliate Report"}
          </button>
        </Card>
      </form>
    </>
  );
};

export default AffliateSubmitForm;
