import Icon from "../../components/Icon";

export const columns = [
  {
    header: "Month",
    accessor: "month",
  },
  {
    header: "Users",
    accessor: "users",
  },
  {
    header: "New Users",
    accessor: "new_users",
    className: "number",
  },
  {
    header: "AET",
    accessor: "average_engagement_time",
    className: "number",
  },
  {
    header: "E Rate",
    accessor: "engagement_rate",
    className: "number",
  },
  {
    header: "Session",
    accessor: "session",
    className: "number",
  },
  {
    header: "IN %",
    accessor: "India_percentage",
    className: "number",
  },
  {
    header: "PK %",
    accessor: "Pakistan_percentage",
    className: "number",
  },
  {
    header: "BN %",
    accessor: "Bangladesh_percentage",
    className: "number",
  },
  {
    header: "VN %",
    accessor: "Vietnam_percentage",
    className: "number",
  },
  {
    header: "CD %",
    accessor: "Cambodia_percentage",
    className: "number",
  },
];

export const actions = [
  {
    html: <Icon name="edit" size="24" />,
    type: "edit",
  },
  {
    icon: "view",
    html: <Icon name="expand" size="24" />,
  },
];
