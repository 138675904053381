import React, { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../../components/Icon";
import { MdEdit } from "react-icons/md";
import { IoMdAnalytics } from "react-icons/io";
import { IoArrowForwardSharp } from "react-icons/io5";
import ModalProject from "../../ModalProject";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ModalGaSubmission from "../../ModalGaSubmission/ModalGaSubmission";
import { FaMoneyBill } from "react-icons/fa";
import ModalAffliateSubmission from "../../ModalAffliateSubmission/ModalAffliateSubmission";


const Control = ({ className, data }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [showGaModal, setShowGaModal] = useState(false);
  const [showAffliate,setshowAffliate] = useState(false);

  const history = useHistory();
  const actions = [
    {
      icon: <MdEdit />,
      action: () => {
        history.push(`/projects/update/${data?.id}`);
      },
    },
    {
      icon: <IoMdAnalytics />,
      action: () => setShowGaModal(true),
    },
    {
      icon: <FaMoneyBill />,
      action: () => setshowAffliate(true),
    },
    {
      icon: <IoArrowForwardSharp />,
      action: () => setVisibleModalProduct(true),
    },
  ];

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action}>
            {x.icon}
          </button>
        ))}
      </div>
      {showAffliate && (
        <ModalAffliateSubmission
          visible={showAffliate}
          setIsVisible={setshowAffliate}
          project={data}
        />
      )}
      {showGaModal && (
        <ModalGaSubmission
          visible={showGaModal}
          setIsVisible={setShowGaModal}
          project={data}
        />
      )}
      {visibleModalProduct && (
        <ModalProject
          visible={visibleModalProduct}
          onClose={() => setVisibleModalProduct(false)}
          data={data}
        />
      )}
    </>
  );
};

export default Control;
