import React, { useEffect, useState } from "react";
import styles from "./ShopDetailPage.module.sass";
import Card from "../../../components/Card";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  clearShopDetail,
  fetchShop,
  shopsSelector,
} from "../../../redux/ShopSlice";
import HtmlParser from "react-html-parser";
import CustomImage from "../../../components/CustomImage/CustomImage";
import Services from "./Services";
import ShopMedia from "./ShopMedia";
import Loader from "../../../components/Loader";
import FeedbackListing from "../../../MyComponents/CreateFeedbackForm/FeedbackListing";

const ShopDetailPage = () => {
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const { detail, isLoading } = useSelector(shopsSelector);
  console.log(detail, "detail");
  useEffect(() => {
    if (detail?.id != id) dispatch(clearShopDetail());
    dispatch(fetchShop(id));
  }, []);
  return (
    <div>
      {isLoading && <Loader />}
      <Card
        className={`${styles.card} mb-20`}
        title="Shop"
        classTitle="title-green"
      >
        <CustomImage src={detail?.image} className={styles.banner_image} />
        <h2 className={styles.heading}>{detail?.name || ""}</h2>
        <p className={styles.description}>
          {HtmlParser(detail?.description || "")}
        </p>
        <ul>
          <li>{detail?.instagram||""}</li>
          <li>{detail?.facebook||""}</li>
          <li>{detail?.twitter||""}</li>
        </ul>
      </Card>
      <Services services={detail?.services} />

      <div className="mt-20">
        <ShopMedia />
      </div>
      <div className="mt-20">
        <FeedbackListing
          related_entity_type="shops"
          related_entity_id={id}
         />
      </div>
    </div>
  );
};

export default ShopDetailPage;
