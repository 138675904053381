import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/userSlice";
import { APP_NAME } from "../config/config";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const UserRefreshLogin = () => {
  const { user } = useSelector((store) => store.user);
  let isLoggedin = user;
  const dispatch = useDispatch();

  const loginUserDetails = localStorage.getItem(APP_NAME);
  if (loginUserDetails) {
    if (!isLoggedin) {
      isLoggedin = true;
      dispatch(setUserInfo(JSON.parse(loginUserDetails)));
    }
  }

  // No login no token moving to login page
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (!isLoggedin && !pathname?.includes("sign-in")) {
      history.push("/sign-in");
    }
    if (
      (isLoggedin && pathname?.includes("sign-in")) ||
      (isLoggedin && pathname?.includes("sign-up"))
    ) {
      history.push("/");
    }
  }, [isLoggedin]);

  return isLoggedin;
};

export default UserRefreshLogin;
