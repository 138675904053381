import React, { useMemo } from "react";
import cn from "classnames";
import styles from "./Users.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { useSelector } from "react-redux";
import { staffOverviewSelector } from "../../redux/staffSlice";
import CustomImage from "../CustomImage/CustomImage";

const users = [
  {
    title: "Gladyce",
    avatar: "/images/content/avatar.jpg",
    url: "/message-center",
  },
  {
    title: "Elbert",
    avatar: "/images/content/avatar-1.jpg",
    url: "/message-center",
  },
  {
    title: "Joyce",
    avatar: "/images/content/avatar-2.jpg",
    url: "/message-center",
  },
];

const Users = ({ className }) => {
  const staffoverview = useSelector(staffOverviewSelector);

  const top3Users = useMemo(() => {
    let staff = staffoverview.total_staff;
    if (!staff || staff?.length < 4) return staff;
    else return staff.slice(0, 3);
  }, [staffoverview]);

  console.log(top3Users, "users");

  return (
    <div className={cn(styles.users, className)}>
      <div className={styles.head}>
        <div className={styles.info}>
          Welcome <strong>{staffoverview?.staff_count} customers</strong>
        </div>
        {/* <Link
          className={cn("button-stroke", styles.button)}
          to="/message-center"
        >
          Send<span> message</span>
        </Link> */}
      </div>
      <div className={styles.list}>
        {top3Users?.map((x, index) => (
          <Link className={styles.item} key={index} to={x.url}>
            <div className={styles.avatar}>
              {!x.profile_image ? (
                <img src="/images/content/avatar.jpg" alt="Avatar" />
              ) : (
                <CustomImage src={x.profile_image} alt="Avatar" />
              )}
            </div>
            <div className={styles.title}>{x.name}</div>
          </Link>
        ))}
        <Link className={styles.all} to="/customers/customer-list">
          <div className={styles.icon}>
            <Icon name="arrow-right" size="24" />
          </div>
          <div className={styles.text}>View all</div>
        </Link>
      </div>
    </div>
  );
};

export default Users;
