import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";

const Panel = ({ onClose, download, title, galleryPanel }) => {
  return (
    <div className={cn(styles.panel, { [styles.panelGallery]: galleryPanel })}>
      <div className={styles.actions}>
    
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.control}>
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="20" />
        </button>
      </div>
    </div>
  );
};

export default Panel;
