import React, { useMemo, useState } from "react";
import CustomModal from "../../../MyComponents/CustomModal/CustomModal";
import Card from "../../../components/Card";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import { claimTypeOptions } from "./data";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import "./ClaimSalaryForm.scss";
import UseEditorState from "../../../hooks/UseEditorState";
import UseApiCalling from "../../../hooks/UseApiCalling";
import Loader from "../../../components/Loader";
import { getRecentMonthOptions } from "../../../utils";

const ClaimSalaryForm = ({ visible, closer }) => {
  const [values, setValues] = useState({
    summary: null,
  });
  const { generateHtml, generateState } = UseEditorState();
  const { isLoading, callApi } = UseApiCalling();

  const cb = () => {};
  const onSubmit = (e) => {
    e.preventDefault();
    let payload = {
      claim_amount: values?.claim_amount,
      claim_locked_fund: values?.claim_locked_fund,
      deduct_debit: values?.deduct_debit,
      summary: generateHtml(values?.summary),
      claim_type: values?.claim_type?.value,
      claim_from: values?.claim_from?.value,
    };
    callApi(`/salary-submissions`, payload, "post", cb);
  };

  const total_claim_amount =
    parseInt(values?.claim_amount || 0) +
    parseInt(values?.claim_locked_fund || 0) -
    parseInt(values?.deduct_debit || 0);
 
  const monthOptions = useMemo(getRecentMonthOptions, []);

  return (
    <CustomModal visible={visible} onClose={closer}>
      {isLoading && <Loader />}
      <Card title="Salary Claim" classTitle="title-green">
        <form onSubmit={onSubmit}>
          <div className="mb-20">
            <CustomDropdown
              label="Month"
              values={values}
              setValues={setValues}
              options={monthOptions}
              name="claim_from"
              placeholder="Select Month"
              isClearable
              required
            />
          </div>
          <div className="mb-20">
            <CustomDropdown
              label="Claim Type"
              placeholder="Select Claim Type"
              options={claimTypeOptions}
              values={values}
              setValues={setValues}
              name="claim_type"
              required
            />
          </div>
          <div className="gap-20 flex align-center mb-20">
            <div className="flex-1">
              <TextInput
                values={values}
                setValues={setValues}
                label="Claim Amount"
                name="claim_amount"
                type="number"
                required
              />
            </div>
            <div className="flex-1">
              <TextInput
                values={values}
                setValues={setValues}
                label="Claim Locked Fund"
                name="claim_locked_fund"
                type="number"
                required
              />
            </div>
          </div>

          <TextInput
            values={values}
            setValues={setValues}
            label="Deduct debt"
            name="deduct_debit"
            type="number"
            required
          />
          <p className="total_claim_amount mt-20 mb-20">
            Total Claim Amount : ${total_claim_amount}
          </p>
          <Editor
            label="Summary"
            name={"summary"}
            values={values}
            setValues={setValues}
          />
          <button type="submit" className="button mt-20">
            Submit Request
          </button>
        </form>
      </Card>
    </CustomModal>
  );
};

export default ClaimSalaryForm;
