import React from "react";
import styles from "./Pill.module.sass";

const Pill = ({ 
  isGreen, 
  isGreenDark, 
  isRed, 
  isRedDark, 
  isPurple, 
  isBlue, 
  isYellow, 
  children }) => {
  return (
    <div
      className={`status 
      ${isGreen && " status-green"}
      ${isGreenDark && " status-green-dark"}
      ${isRed && " status-red"}
      ${isRedDark && " status-red-dark"}
      ${isPurple && " status-purple"}
      ${isBlue && " status-blue"}
      ${isYellow && " status-yellow"}

    `}
    >

      {children}
    </div>
  );
};

export default Pill;
