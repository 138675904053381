import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Axios from "../../redux/axiosInstance";
import Loader from "../../components/Loader";
import Card from "../../components/Card";
import { actions, columns } from "./data";
import Table from "./Table/Table";

const AffliateHistory = ({ project ,setActiveIndex ,setEditData }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Axios.get(`/aflliate?project_id=${project?.id}`)
      .then((res) => {
        if (res?.data?.success) {
          setHistory(res?.data?.data);
          console.log(res.data);
        } else {
          toast.error("Failed to laod the  Afflaite history");
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const actionClickHandler = (type, data) => {
    setEditData(data);
    setActiveIndex(0)
  };
  return (
    <div>
      {loading && <Loader className="page_loader" />}
      <Card classTitle="title-green" title="Affliate Report History">
        <Table
          actionClickHandler={actionClickHandler}
          data={history}
          columns={columns}
        />
        {!loading && !history?.length && (
          <p>No Affliate Report History Found on this Project</p>
        )}
      </Card>
    </div>
  );
};

export default AffliateHistory;
