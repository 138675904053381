import React, { useEffect } from "react";
import styles from "./ServiceDetailPage.module.sass";
import Card from "../../../components/Card";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  clearShopServiceDetail,
  fetchShopService,
  shopsSelector,
} from "../../../redux/ShopSlice";
import Loader from "../../../components/Loader";
import FeedbackListing from "../../../MyComponents/CreateFeedbackForm/FeedbackListing";

const ServiceDetailPage = () => {
  const { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const { service_detail, isLoading } = useSelector(shopsSelector);
  console.log(service_detail, "detail");
  useEffect(() => {
    if (service_detail?.id != id) dispatch(clearShopServiceDetail());
    dispatch(fetchShopService(id));
  }, []);
  return (
    <div>
      {isLoading && <Loader />}
      <Card
        className={`${styles.card} mb-20`}
        title="Service"
        classTitle="title-green"
      >
        <h2 className={styles.heading}>{service_detail?.title || ""}</h2>
        <h3 className={styles.heading}>Price - $ {service_detail?.cost || ""}</h3>
      </Card>

      <div className="mt-20">
        <FeedbackListing related_entity_type="shop_services" related_entity_id={id} />
      </div>
    </div>
  );
};

export default ServiceDetailPage;
