import React, { useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card/index.js";
import { serverColumns } from "./data.js";
import { expiryDateCalculator, numberWithCommas } from "../../../utils.js";
import Icon from "../../../components/Icon.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllExpences } from "../../../redux/expensesSlice.js";
import Loader from "../../../components/Loader/index.js";
import UseApiCalling from "../../../hooks/UseApiCalling.jsx";
import { selectIsAdmin } from "../../../redux/userSlice.js";
import CreateCategories from "../CreateCategories/CreateCategories.jsx";
import { fetchAllCategories } from "../../../redux/categoriesSlice.js";

export const projectListGenereate = (projects) => {
  let title = projects?.map((each) => each.title || "");
  return title?.join(" , ");
};

const ServerTableCard = ({ hostingServers, loading }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { isLoading, callApi } = UseApiCalling();
  const dispatch = useDispatch();

  const cb = () => {
    dispatch(fetchAllCategories());
  };

  const deleteClickHanddler = (data) => {
    if (window.confirm("Are you sure you want to delete")) {
      callApi(`/categories/${data.id}`, null, "delete", cb);
    }
  };
  const editClickHanddler = (data) => {
    setUpdateData(data);
    setShowCreateModal(true);
  };

  const isAdmin = useSelector(selectIsAdmin);

  return (
    <>
      {(isLoading || loading) && <Loader className="page_loader" />}
      {showCreateModal && (
        <CreateCategories
          show={showCreateModal}
          setShow={setShowCreateModal}
          updateData={updateData}
          setUpdateData={setUpdateData}
        />
      )}
      <Card
        className={styles.card}
        title="Categories"
        classTitle="title-green"
        head={
          <button
            onClick={() => setShowCreateModal(true)}
            className={cn("button", styles.button)}
          >
            Add New
          </button>
        }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            {serverColumns?.map((x) => (
              <div className={styles.col}>{x}</div>
            ))}
          </div>
          {hostingServers?.map((x, index) => (
            <div className={styles.row} key={x.id}>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[0]}</div>
                <div>{x?.id || ""}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[1]}</div>
                <div>{x.title}</div>{" "}
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{serverColumns[2]}</div>
                <div>{x.type?.title}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}></div>
                <div className="icons_cont">
                  <button
                    onClick={() => deleteClickHanddler(x)}
                    // disabled={!isAdmin}
                    className="action_icon trash"
                  >
                    <Icon name="trash" size="24" />{" "}
                  </button>
                  <button
                    // disabled={!isAdmin}
                    onClick={() => editClickHanddler(x)}
                    className="action_icon edit"
                  >
                    <Icon name="edit" size="24" />
                  </button>
                </div>{" "}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default ServerTableCard;
