import React from "react";
import cn from "classnames";
import styles from "./File.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { useMemo } from "react";
import './file.css'
import { BACKEND_URL } from "../../config/config";

const File = ({ className, label, tooltip, title, values, setValues, name }) => {

  const fileChangeHandler = (e) => {
    setValues({ ...values, [name]: e?.target?.files?.[0] })
  }

  const previewImage = useMemo(() => {
    if (!values?.[name]) return null
    if (typeof values?.[name] == 'string') return `${BACKEND_URL}/storage/${values?.[name]}`;
    return URL.createObjectURL(values?.[name])
  }, [values?.[name]])

  return (
    <div className={cn(styles.file, className)}>
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div style={{ position: "relative" }} className={styles.wrap}>
        {previewImage && <img className="preview_image" src={previewImage} />}
        <input accept="image/*" onChange={fileChangeHandler} className={`${styles.input} input`} type="file" />
        <div className={`${styles.box} upload_btn`}>
          <Icon name="upload" size="24" />
          {title}
        </div>
      </div>
    </div>
  );
};

export default File;
