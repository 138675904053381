import React, { useMemo } from "react";
import styles from "./TopDevice.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { useSelector } from "react-redux";
import { staffOverviewSelector } from "../../../redux/staffSlice";

// const data = [
//   { name: "Full-Time", value: 340 },
//   { name: "Part-Time", value: 85 },
//   { name: "Probation", value: 1275 },
// ];
const COLORS = ["#8E59FF", "#83BF6E", "#2A85FF"];

const TopDevice = ({ className }) => {
  const staffoverview = useSelector(staffOverviewSelector);
  const contract_type_data = useMemo(() => {
    let full_staff_count = staffoverview.full_staff_count;
    let part_time_staff_count = staffoverview.part_time_staff_count;
    let total_staff = full_staff_count + part_time_staff_count;
    let full_time_staff_percent = (full_staff_count / total_staff) * 100;
    full_time_staff_percent = full_time_staff_percent?.toFixed(2);
    let part_time_staff_percent = (part_time_staff_count / total_staff) * 100;
    part_time_staff_percent = part_time_staff_percent?.toFixed(2);

    let legend = [
      {
        title: "Full-Time",
        percent: full_time_staff_percent,
        icon: "mobile",
        fill: "#8E59FF",
      },
      {
        title: "Part-Time",
        percent: part_time_staff_percent,
        icon: "tablet",
        fill: "#83BF6E",
      },
      {
        title: "Probation",
        percent: 0,
        icon: "desktop",
        fill: "#2A85FF",
      },
    ];
    const data = [
      { name: "Full-Time", value: full_staff_count || 0 },
      { name: "Part-Time", value: part_time_staff_count || 0 },
      { name: "Probation", value: 0 },
    ];
    return { data, legend };
  }, [staffoverview]);

  return (
    <Card
      className={cn(styles.card, className)}
      title="Contract Type"
      classTitle="title-blue"
    >
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Tooltip
              contentStyle={{
                backgroundColor: "#272B30",
                borderColor: "rgba(255, 255, 255, 0.12)",
                borderRadius: 8,
                boxShadow:
                  "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1), inset 0px 0px 1px #000000",
              }}
              labelStyle={{ fontSize: 12, fontWeight: "500", color: "#fff" }}
              itemStyle={{
                padding: 0,
                textTransform: "capitalize",
                fontSize: 12,
                fontWeight: "600",
                color: "#fff",
              }}
            />
            <Pie
              data={contract_type_data.data || []}
              cx={140}
              cy={110}
              innerRadius={88}
              outerRadius={110}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
            >
              {contract_type_data.data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className={styles.legend}>
        {contract_type_data?.legend.map((x, index) => (
          <div className={styles.indicator} key={index}>
            <Icon name={x.icon} size="24" fill={x.fill} />
            <div className={styles.title}>{x.title}</div>
            <div className={styles.percent}>{x.percent}%</div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default TopDevice;
