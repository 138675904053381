import React, { useCallback, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./ModalProduct.module.sass";
import Project from "./Project";
import Icon from "../../components/Icon";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../redux/userSlice";

const ModalProject = ({ visible, onClose, data }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const isAdmin = useSelector(selectIsAdmin);

  return createPortal(
    visible && (
      <div id="modal-product" className={styles.modal}>
        <div className={styles.control}>
          {isAdmin ? (
            <Link
              className={cn("button-white", styles.button)}
              to={`/projects/update/${data?.id}`}
            >
              Edit Project
            </Link>
          ) : (
            <div></div>
          )}
          <button className={styles.close} onClick={onClose}>
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.outer}>
          <Project data={data} />
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalProject;
