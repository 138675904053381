import React, { useState } from "react";
import Card from "../../../components/Card";
import styles from "./ShopDetailPage.module.sass";
import TextInput from "../../../components/TextInput";
import { MEDIA_TYPE_OPTIONS } from "./data";
import UseApiCalling from "../../../hooks/UseApiCalling";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopMedia, shopMediaSelector } from "../../../redux/ShopSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomImage from "../../../components/CustomImage/CustomImage";
import File from "../../../components/File";
import CustomDropdown from "../../../components/Dropdown/CustomDropdown";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";
import cn from "classnames";

const ShopMediaSection = ({ each }) => {
  const [values, setValues] = useState({
    media_type: MEDIA_TYPE_OPTIONS[0],
  });
  const [loading, setLoading] = useState(false);

  const { isLoading, callApi } = UseApiCalling();
  const [errors, setErrors] = useState({});
  const { id } = useParams();

  const dispatch = useDispatch();
  const cb = (response, isSuccess) => {
    setLoading(false);
    if (isSuccess) {
      dispatch(fetchShopMedia(id));
      setValues({});
    }
  };

  const deleteClickHanddler = (data) => {
    if (window.confirm(`Are you sure to delete this Media `))
      callApi(`/shop-media/${data?.id}`, null, "delete", cb);
  };

  const uploadClickHandler = (e, section) => {
    e?.preventDefault();
    console.log(values, "some values");
    let formdata = new FormData();
    formdata.append("shop_id", id);
    formdata.append("media_type", values?.media_type?.value);
    if (values?.media_type?.value == MEDIA_TYPE_OPTIONS[0]?.value) {
      formdata.append("image", values[`${section}_image`]);
    } else {
      formdata.append("media_url", values[`${section}_media_url`]);
    }
    formdata.append("section", section);
    setLoading(true);
    callApi("/shop-media", formdata, "post", cb);
  };
  const shop_media = useSelector(shopMediaSelector);
  const [showForm, setShowForm] = useState(false);
  return (
    <div className="mt-20">
      {(loading || isLoading) && <Loader />}

      <Card
        className={styles.card}
        title={`Media  ${each} Section`}
        classTitle="title-blue"
        head={
          <button
            onClick={() => setShowForm((state) => !state)}
            className={cn("button", styles.button)}
          >
            {!showForm ? "Add Media" : "Hide Add Media"}
          </button>
        }
      >
        {showForm && (
          <form onSubmit={(e) => uploadClickHandler(e, each)}>
            <CustomDropdown
              values={values}
              setValues={setValues}
              label={`Media Type`}
              options={MEDIA_TYPE_OPTIONS}
              name={`media_type`}
            />
            {values?.media_type?.value == MEDIA_TYPE_OPTIONS[0]?.value && (
              <div className={`${styles.images} mt-20`}>
                <File
                  className={styles.field}
                  title="Click or drop image"
                  label="Image"
                  tooltip=""
                  values={values}
                  setValues={setValues}
                  name={`${each}_image`}
                  required
                />
              </div>
            )}
            {values?.media_type?.value == MEDIA_TYPE_OPTIONS[1]?.value && (
              <div className={`${styles.images} mt-20`}>
                <TextInput
                  values={values}
                  setValues={setValues}
                  label="Youtube URL"
                  name={`${each}_media_url`}
                  type="text"
                  required
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            )}
            <div className="flex gap-20 mt-20 mb-20">
              <button className="button ">Upload</button>
              <button
                onClick={() => setShowForm(false)}
                type="button"
                className="button outline-btn"
              >
                Cancel
              </button>
            </div>
          </form>
        )}

        <div className={styles.uploaded_images}>
          {shop_media?.[each]?.map((each) => (
            <div className={styles.relative}>
              <button
                onClick={() => deleteClickHanddler(each)}
                className="action_icon trash"
              >
                <Icon name="trash" size="24" />{" "}
              </button>
              <CustomImage src={each.media_url} />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default ShopMediaSection;
