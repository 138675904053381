import React from "react";
import cn from "classnames";
import styles from "./Switch.module.sass";

const CustomSwitch = ({ className, values, setValues, name, label }) => {
  const onChange = (e) => {
    let checked = e.target.checked;
    setValues({ ...values, [name]: checked });
  };
  return (
    <>
      <div className={styles.label}>{label}</div>
      <label className={cn(styles.switch, className)}>
        <input
          className={styles.input}
          type="checkbox"
          checked={values?.[name] || false}
          onChange={onChange}
          name={name}
        />
        <span className={styles.inner}>
          <span className={styles.box}></span>
        </span>
      </label>
    </>
  );
};

export default CustomSwitch;
