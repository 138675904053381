import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";

const SectionHeading = ({ head, classTitle, classCardHead, title }) => {
  return (
    <div className={cn(styles.head, classCardHead)}>
      <div className={cn(classTitle, styles.title)}>{title}</div>
      {head && head}
    </div>
  );
};

export default SectionHeading;
