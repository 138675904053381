

const dynamicDomain = () => {
    let baseUrl = 'http://universal_backend.test';
    let hostname = window.location.origin;
    if (hostname?.includes('johnt351.sg-host')) {
        baseUrl = "https://adminbackend.johnt351.sg-host.com/public"
    }
    if (hostname?.includes('johnt281.sg-host')) {
        baseUrl = "https://backend.johnt281.sg-host.com/public"
    }
    return baseUrl
}

export const BACKEND_URL = dynamicDomain()

export const APP_NAME = 'universal_frontend'