import React, { useEffect, useState } from "react";
import UseApiCalling from "../../hooks/UseApiCalling";
import Card from "../../components/Card";
import styles from "./ImagesAndCTA.module.sass";
import cn from "classnames";
import Comments from "./Comments";
import CreateFeedbackForm from "./CreateFeedbackForm";

const FeedbackListing = ({ related_entity_type, related_entity_id }) => {
  const [feedbacks, setFeedbacks] = useState(null);
  const { isLoading, callApi } = UseApiCalling();

  const cb = (response, isSuccess) => {
    if (isSuccess) {
      setFeedbacks(response?.data);
    }
  };
  const fetchFeedbacks = () => {
    callApi(
      `/feedbacks?related_entity_type=${related_entity_type}&related_entity_id=${related_entity_id}`,
      null,
      "get",
      cb,
      false
    );
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);

  const [showCreateForm, setShowCreateForm] = useState(false);
  console.log(feedbacks, "some feedbacks");

  return (
    <Card
      head={
        <button
          onClick={() => setShowCreateForm((state) => !state)}
          className={cn("button", styles.button)}
        >
          {!showCreateForm ? "Add Feedback" : "Hide Feedback"}
        </button>
      }
      className={styles.card}
      title="Feedbacks"
      classTitle="title-blue"
    >
  
        <CreateFeedbackForm
          show={showCreateForm}
          related_entity_id={related_entity_id}
          related_entity_type={related_entity_type}
          setShow={setShowCreateForm}
          fetchFeedbacksHandler={fetchFeedbacks}
        />

      <div>
        <Comments data={feedbacks} />
      </div>
    </Card>
  );
};

export default FeedbackListing;
