export const tableColumns = [
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Bonus Amount",
      accessor: "bonus_amount",
    },
    {
      header: "Is Free",
      accessor: "is_free",
      type: "switch",
    },
    {
      header: "Description",
      accessor: "description",
    },
    {
      header: "Question",
      accessor: "question",
    },
    {
      header: "Answer 1",
      accessor: "answer1",
    },
    {
      header: "Answer 2",
      accessor: "answer2",
    },
    {
      header: "Correct Answer",
      accessor: "correct_answer",
    },
    {
      header: "Start Date",
      accessor: "start_date",
      type: "date",
    },
    {
      header: "End Date",
      accessor: "end_date",
      type: "date",
    },
    {
      header: "Is Button Allowed",
      accessor: "is_button_allowed",
      type: "switch",
    },
    {
      header: "Button Label",
      accessor: "button_label",
    },
    {
      header: "Button Link",
      accessor: "button_link",
    },
    {
      header: "Related Entity ID",
      accessor: "related_entity_id",
    },
    {
      header: "Actions",
      accessor: "",
      type: "actions",
    },
  ];
  
  export const formFields = [
    {
      header: "Title",
      accessor: "title",
    },
    {
      header: "Bonus Amount",
      accessor: "bonus_amount",
      type: "number",
    },
    {
      header: "Is Free",
      accessor: "is_free",
      type: "switch",
    },
    {
      header: "Description",
      accessor: "description",
      type: "richtext",
    },
    {
      header: "Question",
      accessor: "question",
    },
    {
      header: "Answer 1",
      accessor: "answer1",
    },
    {
      header: "Answer 2",
      accessor: "answer2",
    },
    {
      header: "Correct Answer",
      accessor: "correct_answer",
    },
    {
      header: "Start Date",
      accessor: "start_date",
      type: "datetime-local",
    },
    {
      header: "End Date",
      accessor: "end_date",
      type: "datetime-local",
    },
    {
      header: "Is Button Allowed",
      accessor: "is_button_allowed",
      type: "switch",
    },
    {
      header: "Button Label",
      accessor: "button_label",
    },
    {
      header: "Button Link",
      accessor: "button_link",
    },
    {
      header: "Related Entity ID",
      accessor: "related_entity_id",
      type : 'dropdown',
    },


  ];
  