import React from "react";
import styles from "./Home.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";

const Home = () => {
  return (
    <>
      <div className={styles.row}>
        <h1>Project Universal Backend</h1>
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Home;
