// axios.js
import axios from 'axios';
import { APP_NAME, BACKEND_URL } from '../config/config';

const Axios = axios.create({
    baseURL: `${BACKEND_URL}/api`,
});

export const LOCAL_STORAGE_NAME = APP_NAME

Axios.interceptors.request.use(
    (config) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))?.token || "";
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Axios;

