import React, { useState } from "react";
import styles from "./newStaff.sass";
import Card from "../../components/Card";
import cn from "classnames";
import TextInput from "../../components/TextInput";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import { ROLE_OPTIONS } from "./data";
import { toast } from "react-toastify";
import Axios from "../../redux/axiosInstance";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewStaff = ({ updateData }) => {
  const [values, setValues] = useState({
    password: "password",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useHistory();
  const addNewStaffHandler = (e) => {
    e?.preventDefault();
    let payload = {...values}
    payload.position = values?.position?.value;


    if (values?.password?.length < 6)
      toast.error("Password must be at least 6 characters");
    setLoading(true);
    Axios.post("/register", payload)
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          toast.success(res?.data?.message || "Staff Created Successfully...");
          history.push("/customers/customer-list");
          return;
        }
        toast.error(res?.data?.message || "Failed to Create Staff ...");
      })
      .catch((e) => {
        setLoading(false);
        toast.error(
          e?.response?.data?.message || "Something went wrong try again.."
        );
      });
  };
  return (
    <div>
      {loading && <Loader className="page_loader" />}
      <Card
        className={cn(styles.card)}
        title="Create Form"
        classTitle="title-green"
        head={<div className="flex align-center gap-10"></div>}
      >
        <form onSubmit={addNewStaffHandler} className={styles.description}>
          <TextInput
            values={values}
            setValues={setValues}
            className="mt-20"
            label="Staff Name"
            name="name"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            className="mt-20"
            label="Email"
            name="email"
            type="email"
            required
            errors={errors}
            setErrors={setErrors}
          />
          <TextInput
            values={values}
            setValues={setValues}
            className="mt-20 mb-20"
            label="Password"
            name="password"
            type="text"
            required
            errors={errors}
            setErrors={setErrors}
          />
          {/* <div className={`${styles.field} mt-20 mb-20`}>
            <CustomDropdown
              name="position"
              options={ROLE_OPTIONS}
              label="Staff Position ( Hired For ) "
              placeholder={"Select Position"}
              values={values}
              setValues={setValues}
              isClearable
              errors={errors}
              setErrors={setErrors}
              required
            />
          </div> */}
          <button type="submit" className="button mt-20">
            {!updateData ? "Create Staff" : "Update Staff"}
          </button>
        </form>
      </Card>
    </div>
  );
};

export default NewStaff;
