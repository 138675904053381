import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { APP_NAME } from "../config/config";
import Axios from "./axiosInstance";
const initialState = {
  isLoading: false,
  user: null,
  error: null,
  field_errors: null,
};

export const signInUser = createAsyncThunk(
  "/login",
  (payload, { rejectWithValue }) => {
    return Axios.post("/login", payload)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message || "User logged in Successfully...");
          return res.data;
        }
        return rejectWithValue(res.data);
      })
      .catch((e) => {
        let formatError = {
          message: "Field Errors",
          field_errors: e?.response?.data?.errors,
        };
        return rejectWithValue(formatError);
      });
  }
);

export const signWithGoogle = createAsyncThunk(
  "/sgignup_with_google",
  (payload, { rejectWithValue }) => {
    return Axios.post("/auth/google", payload).then((res) => {
      if (res?.data?.access_token) {
        toast.success(res?.data?.message || "User logged in Successfully...");
        return res.data;
      }
      return rejectWithValue(res.data);
    });
  }
);

export const signupUser = createAsyncThunk(
  "/register",
  (payload, { rejectWithValue }) => {
    return Axios.post("/register", payload)
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message || "User logged in Successfully...");
          return res.data;
        }
        return rejectWithValue(res.data);
      })
      .catch((e) => {
        let formatError = {
          message: "Field Errors",
          field_errors: e?.response?.data?.errors,
        };
        return rejectWithValue(formatError);
      });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload;
      localStorage.setItem(APP_NAME, JSON.stringify(action.payload));

    },
    clearUserInfo: (state, action) => {
      localStorage.removeItem(APP_NAME);
      state.user = null;
      state.isLoading = false;
    },
    clearFieldErrors: (state, action) => {
      state.error = null;
      state.field_errors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.isLoading = false;
      let user = action.payload?.data?.user;
      user.token = action.payload?.data?.token || "";
      localStorage.setItem(APP_NAME, JSON.stringify(user));
      state.user = user;
    });
    builder.addCase(signInUser.rejected, (state, action) => {
      state.error = action.payload.message;
      state.field_errors = action.payload.field_errors;
      state.isLoading = false;
    });
    /////////////////////////
    builder.addCase(signWithGoogle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signWithGoogle.fulfilled, (state, action) => {
      state.isLoading = false;
      let user = action.payload?.user;
      user.token = action.payload?.access_token || "";
      localStorage.setItem(APP_NAME, JSON.stringify(user));
      state.user = user;
    });
    builder.addCase(signWithGoogle.rejected, (state, action) => {
      if (action.error.message) {
        toast.error(action.error.message);
      }
      state.isLoading = false;
    });
    //////////////////////////////////////////
    builder.addCase(signupUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signupUser.fulfilled, (state, action) => {
      state.isLoading = false;
      let user = action.payload?.data?.user;
      user.token = action.payload?.data?.token || "";
      localStorage.setItem(APP_NAME, JSON.stringify(user));
      state.user = user;
    });
    builder.addCase(signupUser.rejected, (state, action) => {
      state.error = action.payload.message;
      toast.error(action.error.message)
      state.field_errors = action.payload.field_errors;
      state.isLoading = false;
    });
  },
});

export const selectUserInfo = (store)=>{
  return store.user?.user
}
export const selectIsAdmin = (store)=>{
  return store.user?.user?.role === "admin"
}
export const { setUserInfo, clearUserInfo, clearFieldErrors } =
  userSlice.actions;
export default userSlice.reducer;

