import React, { useState } from "react";
import styles from "./Product.module.sass";
import cn from "classnames";
import Details from "./Details";
import Panel from "./Panel";

const Project = ({data}) => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);



  return (
    <div className={cn(styles.product, { [styles.active]: visible })}>
      <Details
        data ={data}
        className={styles.details}
        setValue={setVisible}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Panel className={styles.panel} />
     
    </div>
  );
};

export default Project;
