import React, { useEffect, useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import Row from "./Row";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllStaff, staffSelector } from "../../../redux/staffSlice";

const Table = ({ className, activeTable, setActiveTable ,activeData,setActiveData }) => {
  const [chooseAll, setСhooseAll] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchAllStaff());
  },[])

  const staff = useSelector(staffSelector)

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>
            {/* <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            /> */}
          </div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Days</div>
          <div className={styles.col}>C.Points</div>
          <div className={styles.col}>Salary</div>
          <div className={styles.col}>Traffic</div>
          <div className={styles.col}>Reg.</div>
          <div className={styles.col}>CPC</div>
          <div className={styles.col}>CPR</div>
          <div className={styles.col}>CPS</div>
        </div>
        {staff?.data?.map((x, index) => (
          <Row
            item={x}
            key={index}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            activeData={activeData}
            setActiveData={setActiveData}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
          />
        ))}
      </div>

    </div>
  );
};

export default Table;
