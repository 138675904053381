import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { groupArrayByKey, groupDataByMonthAndYear } from "../utils";

const initialState = {
  data: null,
  overview: null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllStaff = createAsyncThunk(
  "/api/users",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/all-users`).then((res) => res.data);
  }
);
export const fetchStaffOverview = createAsyncThunk(
  "/api/staff-overview",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/staff-overview`).then((res) => res.data);
  }
);

export const staffSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDetailStaffError: (state) => {
      state.detail = null;
    },
    clearAllStaffError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllStaff.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllStaff.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllStaff.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // STAFF OVERVIEW
    builder.addCase(fetchStaffOverview.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStaffOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      state.overview = action.payload?.data;
    });
    builder.addCase(fetchStaffOverview.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default staffSlice.reducer;

/// SELECTORS
export const staffOptionsSelector = (store) => {
  let staff = store?.staff?.data?.map((each) => ({
    label: each?.name,
    value: each?.id,
  }));
  return staff;
};

export const staffOverviewSelector = (store) => {
  let staffoverview = store.staff?.overview;
  let { total_staff, not_reviewed_salary_count } = staffoverview || {};
  let staff_by_month = groupDataByMonthAndYear(total_staff, "created_at");
  let full_staff_count =
    total_staff?.filter((each) => each.is_full_time)?.length || 0;
  let part_time_staff_count = total_staff?.length - full_staff_count;


  let formatted_data = {
    staff_by_month,
    staff_count: total_staff?.length || 0,
    total_staff,
    not_reviewed_salary_count,
    full_staff_count,
    part_time_staff_count
  };
  console.log(formatted_data);

  return formatted_data;
};
export const StaffByCountrySelector = (store) =>{
  let staffoverview = store.staff?.overview;
  let { total_staff } = staffoverview || {};
  let staff_by_country = groupArrayByKey(total_staff,'country');
  return staff_by_country

}

export const staffSelector = (store) => store.staff;
export const staffLoaderSelector = (store) => store.staff?.isLoading;

export const { clearAllStaffError, clearDetailStaffError } = staffSlice.actions;
