export const tableColumns = [
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Category",
    accessor: "category",
    type: "dropdown",
  },
  {
    header: "Prize Amount",
    accessor: "prize_amount",
  },
  {
    header: "Entry Amount",
    accessor: "entry_amount",
  },
  {
    header: "MT per Sub.",
    accessor: "max_tickets_per_submission",
  },
  {
    header: "length",
    accessor: "lucky_number_digits",
  },
  {
    header: "Allow Upload Receipt",
    accessor: "allow_upload_receipt",
  },
  {
    header: "Draw Date",
    accessor: "draw_date",
    type: "date",
  },
  {
    header: "Expired Date",
    accessor: "expired_date",
    type: "date",
  },
  {
    header: "Actions",
    accessor: "",
    type: "actions",
  },
];

export const formFields = [
  {
    header: "Title",
    accessor: "title",
  },
  {
    header: "Logo Image",
    accessor: "logo_image",
    type: "image",
  },
  {
    header: "Cover Image",
    accessor: "cover_image",
    type: "image",
  },
  {
    header: "Description",
    accessor: "text_area",
    type: "richtext",
  },
  {
    header: "Category",
    accessor: "category",
    type: "dropdown",
  },
  {
    header: "Prize Amount",
    accessor: "prize_amount",
    type: "number",
  },
  {
    header: "Entry Amount",
    accessor: "entry_amount",
    type: "number",
  },
  {
    header: "Max Tickets Per Submission",
    accessor: "max_tickets_per_submission",
    type: "number",
  },
  {
    header: "Lucky Number Digits",
    accessor: "lucky_number_digits",
    type: "number",
  },
  {
    header: "Allow Upload Receipt",
    accessor: "allow_upload_receipt",
    type : 'switch'
  },
  {
    header: "Expired Date",
    accessor: "expired_date",
    type: "datetime-local",
  },
  {
    header: "Draw Date",
    accessor: "draw_date",
    type: "datetime-local",
  },
];

export const luckydrawCategoryOptions = [
  {
    label: "Deposit",
    value: "deposit",
  },
  {
    label: "Affliate",
    value: "affliate",
  },
];
