export const claimTypeOptions = [
  {
    label: "Salary Claim",
    value: "salary_claim",
  },
  {
    label: "Report Paid Salary",
    value: "report_paid_salary",
  },
];

export const claimTypeValueToLabel = {
  salary_claim: "Salary Claim",
  report_paid_salary: "Report Paid Salary",
};
