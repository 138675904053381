import React from "react";
import styles from "./Table.module.sass";
import Icon from "../../../components/Icon";
import "./Table.scss";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../../redux/userSlice";

const Table = ({ data, columns, actionClickHandler, actions }) => {
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.row}>
          {columns?.map((x) => (
            <div className={styles.col}>{x.header}</div>
          ))}
          {<div className={styles.col}>Actions</div>}
        </div>
        {data?.map((x, index) => (
          <div className={styles.row} key={x.id}>
            {columns?.map((each) => (
              <div key={each?.id} className={styles.col}>
                <div className={styles.label}>{each.header}</div>
                <div className={`data ${styles[each?.className]}`}>
                  {each?.accessor === "country_affliates" ? (
                    <div className="country_affliate_data">
                      {x?.country_affliates?.map((each) => (
                        <div className="row">
                          <div className={styles.number}>{each.country}</div>
                          <div className={styles.number}>
                            {each.country_registrations}
                          </div>
                          <div className={styles.number}>
                            {each.country_fdu}
                          </div>
                          <div className={styles.number}>
                            {each.country_deposits}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    x[each?.accessor]
                  )}
                </div>
              </div>
            ))}
            <div className={styles.col}>
              <div className={styles.label}>Actions</div>
              {(index === 0 || isAdmin) && (
                <div className="flex gap-20">
                  <div
                    onClick={() => actionClickHandler("Edit", x)}
                    className="action_icon  edit"
                  >
                    <Icon name="edit" size="24" />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Table;
