import React, { useState } from "react";
import styles from "./ProductActivity.module.sass";
import cn from "classnames";
import Card from "../../../components/Card/index.js";
import { tableColumns } from "./data.js";
import { getMonthFromDate } from "../../../utils.js";
import { useSelector } from "react-redux";
import { selectSalaryClaims } from "../../../redux/salaryClaimSlice.js";
import Pill from "../../../MyComponents/Pill/Pill.jsx";
import UserComponent from "../../CustomerList/Table/Row/UserComponent/UserComponent.jsx";
import ClaimDetails from "../ClaimDetails/ClaimDetails.jsx";
import { toast } from "react-toastify";

const Table = () => {
  const [showdetail, setShowDetails] = useState(null);
  const { data } = useSelector(selectSalaryClaims);

  const closer = () => {
    setShowDetails(null);
  };
  const opener = (data) => {
    if (!data.latest_salary_submission) {
      toast.error("no claims available");
      return;
    }
    setShowDetails(data);
  };
  return (
    <>
      {showdetail && <ClaimDetails closer={closer} data={showdetail} />}
      <Card
        className={styles.card}
        title="Staff"
        classTitle="title-green"
        // head={
        //   <button
        //     onClick={() => setShowCreateModal(true)}
        //     className={cn("button", styles.button)}
        //   >
        //     Add Server
        //   </button>
        // }
      >
        <div className={styles.table}>
          <div className={styles.row}>
            {tableColumns?.map((x) => (
              <div className={styles.col}>{x}</div>
            ))}
          </div>
          {data?.map((x, index) => (
            <div onClick={() => opener(x)} className={styles.row} key={x.id}>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[0]}</div>
                <div>
                  <UserComponent
                    avatar={x?.image}
                    description={x?.position}
                    name={x?.name}
                  />
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[1]}</div>
                <div>
                  {x?.latest_salary_submission ? (
                    <Pill isGreenDark>
                      {getMonthFromDate(
                        x?.latest_salary_submission?.claim_from
                      )}
                    </Pill>
                  ) : (
                    <Pill isRed>No Claims Yet</Pill>
                  )}
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[2]}</div>
                <div>
                  <Pill
                    isYellow={x?.latest_salary_submission?.status == "claim"}
                    isGreenDark={x?.latest_salary_submission?.status == "approved"}
                    isRedDark={x?.latest_salary_submission?.status == "rejected"}
                  >
                    {x?.latest_salary_submission?.status}
                  </Pill>
                </div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[3]}</div>
                <div>${x?.latest_salary_submission?.claim_amount || 0}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[4]}</div>
                <div>{0}</div>
              </div>
              <div className={styles.col}>
                <div className={styles.label}>{tableColumns[5]}</div>
                <div>{x?.salary_claim_count || 0}</div>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};

export default Table;
