import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  isLoading: false,
  detail: null,
  error: null,
};

export const fetchAllCategories = createAsyncThunk(
  "fetchAllCategories",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`/categories${queryString || ""}`).then((res) => res.data);
  }
);
export const fetchCategory = createAsyncThunk(
  "fetchCategory",
  (id, { rejectWithValue }) => {
    return Axios.get(`/categories/${id}`).then((res) => res.data);
  }
);


export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    clearDetailProjectData: (state) => {
      state.detail = null;
    },
    clearAllProjectsError: (state, action) => {
      state.error = null;
    },
    setManagerProject: (state, action) => {
      state.manager_projects = action.payload;
    },
    setDetailProject: (state, action) => {
      state.detail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategories.pending, (state) => {
      if (!state.data) state.isLoading = true;
    });
    builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchAllCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////// FETCH SINGLE category ////////////////
    builder.addCase(fetchCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.detail = action.payload?.data;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

  },
});

export default categoriesSlice.reducer;

/// SELECTORS
export const categoriesSelector = (store) => store.categories;
export const categoriesLoader = (store) => store.categories.isLoading



export const {
  clearDetailProjectData,
  setDetailProject,
  setManagerProject,
} = categoriesSlice.actions;

