export const EmployeeList = [
    {
        label: "Captain India",
        value: 1
    },
    {
        label: "Muhammad Bilal",
        value: 2
    },
    {
        label: "Hamayun",
        value: 3
    },
    {
        label: "GM David",
        value: 4
    },
]



export const teamsInputs = [
    {
        tooltip: "Who manages the Project and Deadlines",
        label: "Product Manager",
        name: "product_manager"
    },
    {
        tooltip: "Who manages the Project and Deadlines",
        label: "Vice Manager",
        name: "vice_manager"
    },
    {
        tooltip: "Develops Backend Laravel",
        label: "Backend Developer",
        name: "backend_developer"
    },
    {
        label: "Frontend Developer",
        name: "frontend_developer"
    },
    {
        label: "Tester",
        name: "tester"
    },
]

export const ROLE_TYPES = {
    productManager: 'PRODUCT_MANAGER',
    frontend: 'FRONTEND',
    backend: 'BACKEND',
    qc: 'QC_COMPLAIN',
    uiUx: 'UI_UX',
    backendBackup: 'BACKEND_BACKUP',
    wordpressTechnical: 'WORDPRESS_TECHNICAL',
    graphicDesigner: 'GRAPHIC_DESIGNER',
    offpageResearch: 'OFFPAGE_RESEARCH',
    competitorResearch: 'COMPETITOR_RESEARCH',
    inpageSeo: 'INPAGE_SEO',
    content1: 'CONTENT_1',
    content2: 'CONTENT_2',
    content3: 'CONTENT_3',
    content4: 'CONTENT_4',
    content5: 'CONTENT_5',
    fakeComment: 'FAKE_COMMENT',
    socialMedia: 'SOCIAL_MEDIA'
};
export const roleToCPoints = {
    [ROLE_TYPES.productManager] : 10,
    [ROLE_TYPES.frontend] : 5,
    [ROLE_TYPES.backend] : 8,
    [ROLE_TYPES.qc] : 2,
    [ROLE_TYPES.uiUx] : 3,
    [ROLE_TYPES.backendBackup] : 3,
    [ROLE_TYPES.wordpressTechnical] : 3,
    [ROLE_TYPES.graphicDesigner] : 5,
    [ROLE_TYPES.offpageResearch] : 10,
    [ROLE_TYPES.competitorResearch] : 2,
    [ROLE_TYPES.inpageSeo] : 5,
    [ROLE_TYPES.content1] : 5,
    [ROLE_TYPES.content2] : 3,
    [ROLE_TYPES.content3] : 3,
    [ROLE_TYPES.content4] : 3,
    [ROLE_TYPES.content5] : 3,
    [ROLE_TYPES.fakeComment] : 2,
    [ROLE_TYPES.socialMedia] : 10,
}
//   ROLES DATA AT BACKEND
export const AllTeams = [
    { id: ROLE_TYPES.productManager, name: 'Product Manager' },
    { id: 'UI_UX', name: 'UI/UX' },
    { id: 'FRONTEND', name: 'Frontend' },
    { id: 'BACKEND', name: 'Backend' },
    { id: 'BACKEND_BACKUP', name: 'Backend Backup' },
    { id: 'WORDPRESS_TECHNICAL', name: 'Wordpress Technical' },
    { id: 'GRAPHIC_DESIGNER', name: 'Graphic Designer' },
    { id: 'QC_COMPLAIN', name: 'QC & Complain' },
    { id: 'OFFPAGE_RESEARCH', name: 'Offpage research' },
    { id: 'COMPETITOR_RESEARCH', name: 'Competitor Research' },
    { id: 'INPAGE_SEO', name: 'Inpage SEO' },
    { id: 'CONTENT_1', name: 'Content 1 (Lead)' },
    { id: 'CONTENT_2', name: 'Content 2' },
    { id: 'CONTENT_3', name: 'Content 3' },
    { id: 'CONTENT_4', name: 'Content 4' },
    { id: 'CONTENT_5', name: 'Content 5' },
    { id: 'FAKE_COMMENT', name: 'Fake Comment (Web & Social)' },
    { id: 'SOCIAL_MEDIA', name: 'Social Media' }
];

export const currentTeamInputs = [
    { id: 'PRODUCT_MANAGER', name: 'Product Manager' },
    { id: 'FRONTEND', name: 'Frontend' },
    { id: 'BACKEND', name: 'Backend' },
    { id: 'QC_COMPLAIN', name: 'QC & Complain' },
]




let teamsPayload = [
    { role_id: "PRODUCT_MANAGER", user_id: 1 },
    { role_id: "FRONTEND", user_id: 1 },
    { role_id: "BACKEND", user_id: 1 },
]