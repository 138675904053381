import React, { useEffect } from "react";
import styles from "./Customers.module.sass";
import Overview from "./Overview";
import PositionType from "./TrafficChannel";
import ActiveCustomers from "./ActiveCustomers";
import ShareProducts from "./ShareProducts";
import ClaimRequests from "../../components/RefundRequests";
import ContractType from "./TopDevice";
import TopCountry from "./TopCountry";
import Message from "./Message";
import NewCustomer from "./NewCustomer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStaffOverview,
  staffLoaderSelector,
  staffOverviewSelector,
} from "../../redux/staffSlice";
import Loader from "../../components/Loader";

const Customers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchStaffOverview());
  }, []);

  const isLoading = useSelector(staffLoaderSelector);
  return (
    <div className={styles.row}>
      {isLoading && <Loader />}
      <div className={`${styles.col} ${styles.flex_1}`}>
        <Overview className={`${styles.card}`} />
        <PositionType className={styles.card} />
        {/* <ActiveCustomers className={styles.card} /> */}
        {/* <ShareProducts className={styles.card} /> */}
      </div>
      <div className={styles.col}>
        <ClaimRequests
          className={styles.card}
          title="Claim requests"
          classTitle="title-red"
        />
        <ContractType className={styles.card} />
        <TopCountry className={styles.card} />
        {/* <Message className={styles.card} /> */}
        {/* <NewCustomer className={styles.card} /> */}
      </div>
    </div>
  );
};

export default Customers;
