import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Overview from "./Overview";

const Details = ({ className, data }) => {
  return (
    <div className={cn(styles.details, className)}>
      <Overview data={data} />
    </div>
  );
};

export default Details;
