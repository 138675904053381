import React from "react";
import styles from "../Row.module.sass";
import cn from "classnames";

const UserComponent = ({ avatar, name, description }) => {
  return (
    <div className="flex">
      <div className={styles.avatar}>
        {avatar ? (
          <img src={avatar} alt="" />
        ) : (
          <img src="/images/content/avatar.jpg" alt="Avatar" />
        )}
      </div>
      <div className={styles.details}>
        <div className={styles.user}>{name || ""}</div>
        <div className={styles.email}>{description || ""}</div>
      </div>
    </div>
  );
};

export default UserComponent;
