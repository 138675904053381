import React, { useMemo, useState } from "react";
import cn from "classnames";
import styles from "./Search.module.scss";
import Icon from "../../Icon";
import Item from "./Item";
import Suggestion from "./Suggestion";
import useDebounce from "../../../hooks/UseDebounce/UseDebounce";
import { useSelector } from "react-redux";
import { staffSelector } from "../../../redux/staffSlice";
import { projectSelector } from "../../../redux/projectSlice";
import ModalProject from "../../ModalProject";

// const projects = [
//   {
//     title: "Project A",
//     content: "Under Development",
//     image: "/images/content/product-pic-3.jpg",
//     image2x: "/images/content/product-pic-3@2x.jpg",
//   },
//   {
//     title: "Project B",
//     content: "Under Development",
//     image: "/images/content/product-pic-4.jpg",
//     image2x: "/images/content/product-pic-4@2x.jpg",
//   },
// ];

// const staffs = [
//   {
//     title: "Captain",
//     content: "India",
//     icon: "photos",
//   },
//   {
//     title: "Abur Rahman",
//     content: "India",
//     icon: "photos",
//   },
// ];

const Search = ({ className }) => {
  const [searchValue, setSearchValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);

  const { data: staffs } = useSelector(staffSelector);
  const { data: projects } = useSelector(projectSelector);

  const defaultSearchValue = useDebounce(searchValue, 300);

  const [searchProjects, searchStaffs] = useMemo(() => {
    if (!defaultSearchValue) return [[], []]
    if (!visible) setVisible(true)
    let lowerCaseSearchValue = defaultSearchValue?.toLowerCase();
    let filterProjects = projects?.filter(project => project?.title?.toLowerCase()?.includes(lowerCaseSearchValue));
    let filterStaffs = staffs?.filter(staff => staff?.name?.toLowerCase()?.includes(lowerCaseSearchValue));
    return [filterProjects, filterStaffs]
  }, [defaultSearchValue])


  const searchChangeHandler = (e) => {
    let value = e.target.value;
    setSearchValue(value || '')
  }



  return (
    <>
      <div
        className={cn(styles.search, className, { [styles.active]: visible })}
      >
        <div className={styles.head}>
          <button className={styles.start}>
            <Icon name="search" size="24" />
          </button>
          <button className={styles.direction}>
            <Icon name="arrow-left" size="24" />
          </button>
          <input
            className={styles.input}
            type="text"
            placeholder="Search a Keyword"
            onChange={searchChangeHandler}
          />
          <button className={styles.result}>⌘ F</button>
          <button className={styles.close} onClick={() => {
            setVisible(false)
            setSearchValue("")
          }}>
            <Icon name="close-circle" size="24" />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.box}>
            <div className={styles.category}>Search Projects</div>
            <div className={styles.list}>
              {searchProjects.map((x, index) => (
                <Item
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.category}>Search Staff</div>
            <div className={styles.list}>
              {searchStaffs.map((x, index) => (
                <Suggestion
                  className={styles.item}
                  item={x}
                  key={index}
                  onClick={() => setVisibleModalProduct(true)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <ModalProject
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Search;
