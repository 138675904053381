import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopOptions, shopOptionsSelector } from "../../redux/ShopSlice";
import Card from "../../components/Card";
import {
  PromotionsLoader,
  PromotionsSelector,
  fetchAllPromotions,
} from "../../redux/promotionsSlice";
import CustomDropdown from "../../components/Dropdown/CustomDropdown";
import Table from "../../MyComponents/CommonTable/Table";
import { formFields, tableColumns } from "./data";
import UseEditorState from "../../hooks/UseEditorState";
import UseApiCalling from "../../hooks/UseApiCalling";

const PromotionsListing = () => {
  const { generateHtml, generateState } = UseEditorState();
  const { isLoading, callApi } = UseApiCalling();
  const dispatch = useDispatch();
  const [values, setValues] = useState({});

  const promotions = useSelector(PromotionsSelector);
  const loading = useSelector(PromotionsLoader);

  useEffect(() => {
    dispatch(fetchAllPromotions());
    dispatch(fetchShopOptions());
  }, []);

  const filterPromotions = useMemo(() => {
    if (!values.type_shop) return promotions.data;
    else {
      let filteredPromotions = promotions?.data?.filter(
        (each) => each.related_entity_id === values.type_shop?.value
      );
      return filteredPromotions;
    }
  }, [values, promotions]);

  console.log(promotions, "promotions");
  const shopOptions = useSelector(shopOptionsSelector);

  const generatePayload = (values) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("bonus_amount", values.bonus_amount);
    formData.append("is_free", values.is_free);
    formData.append("description", generateHtml(values.description));
    formData.append("question", values.question);
    formData.append("answer1", values.answer1);
    formData.append("answer2", values.answer2);
    formData.append("correct_answer", values.correct_answer);
    formData.append("start_date", values.start_date);
    formData.append("end_date", values.end_date);
    formData.append("is_button_allowed", values.is_button_allowed);
    formData.append("button_label", values.button_label);
    formData.append("button_link", values.button_link);
    formData.append("related_entity_id", values.related_entity_id?.value);
    formData.append("related_entity_type", "Shop");
    // formData.append("user_id", values.user_id);
    formData.append("id", values.id);

    return formData;
  };

  const deleteClickHandler = (values) => {
    if (
      !window.confirm(
        `Are you sure you want to delete promotion "${values.title}" ? `
      )
    )
      return;
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        // dispatch(fetchAllLuckydraw());
      }
    };
    callApi(`/promotions/${values.id}`, null, "delete", cb);
  };

  const createNewHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllPromotions());
      }
    };
    callApi("/promotions", payload, "post", cb);
  };
  const updateDataHandler = (values) => {
    let format = {};
    format.id = values.id;
    format.title = values.title;
    format.bonus_amount = values.bonus_amount;
    format.is_free = values.is_free ? true : false;
    format.description = generateState(values.description);
    format.question = values.question;
    format.answer1 = values.answer1;
    format.answer2 = values.answer2;
    format.correct_answer = values.correct_answer;
    format.start_date = values.start_date;
    format.end_date = values.end_date;
    format.is_button_allowed = values.is_button_allowed ? true : false;
    format.button_label = values.button_label;
    format.button_link = values.button_link;
    format.related_entity_id = shopOptions?.find(
      (each) => each.value == values.related_entity_id
    );
    format.related_entity_type = values.related_entity_type;
    return format;
  };

  const updateApiHandler = (values, formCloser) => {
    let payload = generatePayload(values);
    payload.append("_method", "put");
    const cb = (response, isSuccess) => {
      if (isSuccess) {
        formCloser();
        dispatch(fetchAllPromotions());
      }
    };
    callApi(`/promotions/${values.id}`, payload, "post", cb);
  };

  return (
    <>
      <div className={styles.section}>
        <Card className={styles.card} title="Filters" classTitle="title-blue">
          <div style={{ flexWrap: "wrap" }} className="flex gap-20 flex-wrap">
            <CustomDropdown
              values={values}
              setValues={setValues}
              label={`Filter by Shop`}
              options={shopOptions || []}
              name={`type_shop`}
            />
          </div>
        </Card>

        <Table
          actions
          deleteClickHanddler={deleteClickHandler}
          loading={loading}
          tableData={filterPromotions || []}
          formFields={formFields}
          tableColumns={tableColumns}
          options={{
            related_entity_id: shopOptions,
          }}
          createNewHandler={createNewHandler}
          updateDataHandler={updateDataHandler}
          updateApiHandler={updateApiHandler}
          title={"Luckydraw"}
        />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default PromotionsListing;
