import React, { useEffect, useMemo, useState } from "react";
import styles from "./Payouts.module.sass";
import Table from "./CategoriesTableCard";
import TooltipGlodal from "../../components/TooltipGlodal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllShops,
  shopsLoader,
  shopsSelector,
} from "../../redux/ShopSlice";
import {
  categoriesTypesSelector,
  fetchAllCategoriesTypesWithCategories,
} from "../../redux/categoriesTypesSlice";
import CategoryDropdown from "./CreateForm/CategoryDropdown";
import Card from "../../components/Card";

const ShopsListing = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});

  const shops = useSelector(shopsSelector);
  const loading = useSelector(shopsLoader);
  const { data } = useSelector(categoriesTypesSelector);

  useEffect(() => {
    dispatch(fetchAllShops());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllCategoriesTypesWithCategories());
    // dispatch(fetchAllCategories())
  }, []);

  const filterShops = useMemo(()=>{
    let type_ids = [];
    data?.map((type) => {
      let selected_types = values[`type_${type?.id}`];
      selected_types?.forEach((type) => {
        type_ids.push(type.value);
      });
    });
    if(!type_ids.length) return shops?.data;

    let shops_filter = shops?.data?.filter(shop=>{
      let isPresent = false;
      shop?.categories?.forEach(each=>{
        if(type_ids?.includes(each?.id)){
          isPresent = true
        }
      })
      console.log(isPresent,'is present')
      return isPresent;
    })
    
    return shops_filter;
  },[data,values,shops])



  return (
    <>
      <div className={styles.section}>
        <Card className={styles.card} title="Filters" classTitle="title-blue">
          <div style={{flexWrap:"wrap"}} className="flex gap-20 flex-wrap">
            {data?.map((each) => (
              <div style={{minWidth : "250px"}}>
                <CategoryDropdown
                  values={values}
                  setValues={setValues}
                  label={`Category ${each.title}`}
                  options_data={each?.categories || []}
                  name={`type_${each.id}`}
                />{" "}
              </div>
            ))}
          </div>
        </Card>

        <Table loading={loading} shops={filterShops} />
      </div>
      <TooltipGlodal />
    </>
  );
};

export default ShopsListing;
