import React from "react";
import cn from "classnames";
import styles from "./Card.module.sass";
import SectionHeading from "./SectionHeading";

const Card = ({
  className,
  title,
  classTitle,
  classCardHead,
  head,
  children,
}) => {
  return (
    <div className={cn(styles.card, className)}>
      {title && (
        <SectionHeading 
        classCardHead={classCardHead} 
        classTitle={classTitle}
        head={head}
        title={title}
        />
      )}
      {children}
    </div>
  );
};

export default Card;
