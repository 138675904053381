import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "./axiosInstance";
import { ADS_PAY, API, HOSTING_SERVER, PLUGIN, SOFTWARE, THEME } from "./data";

const initialState = {
  [HOSTING_SERVER]: null,
  [API]: null,
  [ADS_PAY]: null,
  [THEME]: null,
  [PLUGIN]: null,
  [SOFTWARE]: null,
  overview : null,
  all: null,
  isLoading: false,
  error: null,
};

export const fetchExpenseOverview = createAsyncThunk(
  "fetchExpenseOverview",
  (month, { rejectWithValue }) => {
    return Axios.get(`/expenses-overview?month=${month}`).then(
      (res) => res.data
    );
  }
);
export const fetchAllExpences = createAsyncThunk(
  "fetchExpenses",
  (type, { rejectWithValue }) => {
    return Axios.get(`/expenses-by-type/${type || ""}`).then((res) => ({
      data: res.data?.data,
      type: type,
    }));
  }
);

export const fetchAllTypesExpenses = createAsyncThunk(
  "all-expenses",
  (type, { rejectWithValue }) => {
    return Axios.get(`/expenses`).then((res) => ({
      data: res.data?.data,
      type: type,
    }));
  }
);
export const fetchProject = createAsyncThunk(
  "fetchProject",
  (id, { rejectWithValue }) => {
    return Axios.get(`/projects/${id}`).then((res) => res.data);
  }
);
export const fetchManagerProjects = createAsyncThunk(
  "manager_projects",
  (payload, { rejectWithValue }) => {
    return Axios.get(`my_manager_projects`).then((res) => res.data);
  }
);

export const expensesSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    exampleReducer: (state) => {
      state.detail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllExpences.pending, (state, action) => {
      console.log(action);
      let type = action?.meta?.arg;
      if (
        (type === "server" && !state[HOSTING_SERVER]) ||
        (type === "api" && !state[API]) ||
        (type === "ads-pay" && !state[ADS_PAY]) ||
        (type === "theme" && !state[THEME]) ||
        (type === "plugin" && !state[PLUGIN]) ||
        (type === "software" && !state[SOFTWARE])
      ) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchAllExpences.fulfilled, (state, action) => {
      state.isLoading = false;
      let type = action.payload.type;
      let data = action.payload.data;
      if (type === "server") {
        state[HOSTING_SERVER] = data;
      } else if (type === "api") {
        state[API] = data;
      } else if (type === "api") {
        state[ADS_PAY] = data;
      } else if (type === "theme") {
        state[THEME] = data;
      } else if (type === "plugin") {
        state[PLUGIN] = data;
      } else if (type === "software") {
        state[SOFTWARE] = data;
      }
    });
    builder.addCase(fetchAllExpences.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    //////////
    builder.addCase(fetchAllTypesExpenses.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllTypesExpenses.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload.data;
      state.all = data;
    });
    builder.addCase(fetchAllTypesExpenses.rejected, (state, action) => {
      state.isLoading = false;
    });
    //////////
    builder.addCase(fetchExpenseOverview.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExpenseOverview.fulfilled, (state, action) => {
      state.isLoading = false;
      let data = action.payload.data;
      state.overview = data;
    });
    builder.addCase(fetchExpenseOverview.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default expensesSlice.reducer;

/// SELECTORS
export const selectExpenseLoader = (store) => store.expenses?.isLoading;
export const selectExpensesServers = (store) => store.expenses[HOSTING_SERVER];
export const selectExpensesApi = (store) => store.expenses[API];
export const selectExpensesAdsPay = (store) => store.expenses[ADS_PAY];
export const selectExpensesTheme = (store) => store.expenses[THEME];
export const selectExpensesPlugin = (store) => store.expenses[PLUGIN];
export const selectExpensesSoftware = (store) => store.expenses[SOFTWARE];
export const selectExpensesOverview = (store) => store.expenses?.overview;

export const selectExpenseServerOptions = (store) => {
  let data = store.expenses?.all?.filter((item) => item.type === "server");
  let serverOptions = data?.map((each) => ({
    value: each.id,
    label: each.company,
  }));
  return serverOptions;
};
export const selectExpenseApiOptions = (store) => {
  let data = store.expenses?.all?.filter((item) => item.type === "api");
  let serverOptions = data?.map((each) => ({
    value: each.id,
    label: each.company,
  }));
  return serverOptions;
};
export const selectExpenseThemeOptions = (store) => {
  let data = store.expenses?.all?.filter((item) => item.type === "theme");
  let serverOptions = data?.map((each) => ({
    value: each.id,
    label: each.title,
  }));
  return serverOptions;
};
export const selectExpensePluginOptions = (store) => {
  let data = store.expenses?.all?.filter((item) => item.type === "plugin");
  let serverOptions = data?.map((each) => ({
    value: each.id,
    label: each.title,
  }));
  return serverOptions;
};

export const { exampleReducer } = expensesSlice.actions;
